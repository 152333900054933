import React from 'react';
import styled from 'styled-components';
import Dropdown from './Dropdown';

const StyledDropdown = styled(Dropdown)`
  padding: 8px 16px;
  background: ${({ $transparent, theme }) => ($transparent === 'true' ? 'transparent' : theme.colors.main)};
  border-radius: 8px;
  backdrop-filter: blur(2px);
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.white70}; /* Use theme color */

  &:focus {
    outline: none;
  }
`;

const LabelContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 2px; /* Reduced gap between text and icon */
  &:hover {
    color: ${({ theme }) => theme.colors.main}; /* Change text color */
`;

const NavigationDropdown = ({ label, options }) => {
  return (
    <StyledDropdown
      label={
        <LabelContainer>
          {label}
          <img src={`${process.env.PUBLIC_URL}/img/dropdown.svg`} alt="Dropdown" />
        </LabelContainer>
      }
      options={options}
      transparent={true}
    />
  );
};

export default NavigationDropdown;