import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  padding: 8px 16px;
  background: ${({ $transparent, $active, theme }) => ($transparent ? 'transparent' : $active ? 'rgba(189, 214, 54, 0.1)' : 'transparent')};
  border-radius: 8px;
  // backdrop-filter: blur(2px);
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border: none;
  outline: none;
  color: ${({ theme, $active }) => ($active ? theme.colors.main : theme.colors.white70)}; /* Use theme color */
  white-space: nowrap; /* Prevent text from wrapping */

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.main}; /* Change text color on hover */
    background: ${({ $transparent, theme }) => ($transparent === 'true' ? 'rgba(189, 214, 54, 0.1)' : 'rgba(189, 214, 54, 0.1)')}; /* Optional: Change background on hover */
  }
`;

const HoverStyledButton = styled(StyledButton)`
  color: ${({ theme }) => theme.colors.main}; /* Change text color */
  background: ${({ $transparent, theme }) => ($transparent === 'true' ? 'rgba(189, 214, 54, 0.1)' : 'rgba(189, 214, 54, 0.1)')}; /* Change background */
`;

const NewHoverStyledButton = styled(StyledButton)`
  color: ${({ theme }) => theme.colors.main}; /* Change text color */
  background: ${({ $transparent, theme }) => ($transparent === 'true' ? 'rgba(100, 100, 100, 0.25)' : '#555555')}; /* New hover background */

  &:hover {
    background: ${({ $transparent, theme }) => ($transparent === 'true' ? 'rgba(189, 214, 54, 0.15)' : 'rgba(189, 214, 54, 0.1)')}; /* Change background */
  }
`;

const LowStyledButton = styled(StyledButton)`
  color: ${({ theme }) => theme.colors.white70}; /* Change text color */
  background: ${({ $transparent, theme }) => ($transparent === 'true' ? 'rgba(100, 100, 100, 0.1)' : '#333333')}; /* Change background */
  margin-left: 0.3rem; /* Add spacing to the left of the button */

  @media (max-width: 82rem) {
    margin-left: 1rem; /* Add spacing to the left of the button */
  }
`;

const RangeStyledButton = styled(StyledButton)`
  color: ${({ theme, $active }) => ($active ? theme.colors.main : theme.colors.white70)}; /* Change text color */
  background: ${({ $transparent, $active, theme }) => ($transparent === 'true' ? ($active ? 'rgba(189, 214, 54, 0.1)' : 'rgba(100, 100, 100, 0.1)') : ($active ? 'rgba(189, 214, 54, 0.1)' : 'rgba(189, 214, 54, 0.1)'))}; /* Change background */

  &:hover {
    background: ${({ $transparent, theme }) => ($transparent === 'true' ? 'rgba(189, 214, 54, 0.1)' : 'rgba(189, 214, 54, 0.1)')}; /* Change background */
  }
`;

const LargeHoverStyledButton = styled(HoverStyledButton)`
  padding: 0.8rem 2rem;
  max-width: 10rem;
  font-size: 0.9rem;
  margin-top: 1rem; /* Add spacing above the button */
  justify-content: center;
  color: ${({ theme }) => theme.colors.main}; /* Change text color */
  background: ${({ $transparent, theme }) => ($transparent === 'true' ? 'rgba(100, 100, 100, 0.25)' : '#555555')}; /* New hover background */
  
  @media (max-width: 48rem) {
    // width: 30%; /* Fill available width on mobile devices */
    padding: 0.6rem 1rem;
    font-size: 0.7rem; /* Adjust font size for mobile devices */
  }

  &:hover {
    background: ${({ $transparent, theme }) => ($transparent === 'true' ? 'rgba(189, 214, 54, 0.15)' : 'rgba(189, 214, 54, 0.1)')}; /* Change background */
  }
`;

const Icon = styled.img`
  width: 1rem;
  height: 1rem;
`;

// A new style for the button to span the full width
const FullWidthButton = styled(StyledButton)`
  width: 100%;
  padding: 0.8rem 3rem;
  font-size: 1rem;
  margin-top: 1rem; /* Add spacing above the button */
  justify-content: center;
  color: ${({ theme }) => theme.colors.main}; /* Change text color */
  background: ${({ $transparent, theme }) => ($transparent === 'true' ? 'rgba(100, 100, 100, 0.25)' : '#555555')}; /* New hover background */
  
  @media (max-width: 48rem) {
    width: 100%; /* Fill available width on mobile devices */
  }

  &:hover {
    background: ${({ $transparent, theme }) => ($transparent === 'true' ? 'rgba(189, 214, 54, 0.15)' : 'rgba(189, 214, 54, 0.1)')}; /* Change background */
  }
`;

const ButtonComponent = ({ text, transparent, active, hoverStyle, newHoverStyle, lowStyle, rangeStyle, large, full, icon, onClick }) => {
  const transparentStr = transparent ? 'true' : 'false';
  if (large) {
    return (
      <LargeHoverStyledButton className="poppins-medium" $transparent={transparentStr} onClick={onClick}>
        {text}
        {icon && <Icon src={`${process.env.PUBLIC_URL}/img/${icon}`} alt="icon" />}
      </LargeHoverStyledButton>
    );
  }
  if (hoverStyle) {
    return (
      <HoverStyledButton className="poppins-medium" $transparent={transparentStr} onClick={onClick}>
        {text}
        {icon && <Icon src={`${process.env.PUBLIC_URL}/img/${icon}`} alt="icon" />}
      </HoverStyledButton>
    );
  }
  if (newHoverStyle) {
    return (
      <NewHoverStyledButton className="poppins-medium" $transparent={transparentStr} onClick={onClick}>
        {text}
        {icon && <Icon src={`${process.env.PUBLIC_URL}/img/${icon}`} alt="icon" />}
      </NewHoverStyledButton>
    );
  }
  if (lowStyle) {
    return (
      <LowStyledButton className="poppins-medium" $transparent={transparentStr} onClick={onClick}>
        {text}
        {icon && <Icon src={`${process.env.PUBLIC_URL}/img/${icon}`} alt="icon" />}
      </LowStyledButton>
    );
  }
  if (rangeStyle) {
    return (
      <RangeStyledButton className="poppins-medium" $transparent={transparentStr} $active={active} onClick={onClick}>
        {text}
        {icon && <Icon src={`${process.env.PUBLIC_URL}/img/${icon}`} alt="icon" />}
      </RangeStyledButton>
    );
  }
  if (full) {
    return (
      <FullWidthButton className="poppins-medium" $transparent={transparentStr} onClick={onClick}>
        {text}
        {icon && <Icon src={`${process.env.PUBLIC_URL}/img/${icon}`} alt="icon" />}
      </FullWidthButton>
    );
  }
  return (
    <StyledButton className="poppins-medium" $transparent={transparentStr} $active={active} onClick={onClick}>
      {text}
      {icon && <Icon src={`${process.env.PUBLIC_URL}/img/${icon}`} alt="icon" />}
    </StyledButton>
  );
};

export default ButtonComponent;