import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ButtonComponent from './Button';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 15rem;
  padding: 3rem 2rem;
  gap: 2rem; /* Gap between main areas */
  flex-shrink: 0;
  border-radius: 24px;
  background: rgba(244, 244, 244, 0.04);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(1px);
`;

const PlanContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem; /* Gap between icon and title */
`;

const Icon = styled.img`
  width: 100%;
  height: auto;
`;

const TitleDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0; /* No gap between title and description */
`;

const Tier = styled.h3`
  font-size: 1.2rem;
  text-align: left;
  margin: 0;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
`;

const Product = styled.p`
  font-size: 0.8rem;
  text-align: left;
  margin: 0;
  min-width: 5rem;
  color: ${({ theme }) => theme.colors.white40};
`;

const Description = styled.p`
  font-size: 1rem;
  text-align: left;
  margin: 0;
  color: ${({ theme }) => theme.colors.white40};
`;

const PricingContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.5rem; /* Gap between price and duration */
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Price = styled.span`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.white};
`;

const Duration = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.white40};
`;

const CTAButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const CTAButton = styled(ButtonComponent)`
  width: 100%; /* Make the button take the full width of the container */
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 3rem;
`;

const Container = styled.div`
  display: flex;
  width: 80%;
  // max-width: 70rem;
  height: auto;
  flex-shrink: 0;
  border-radius: 1.5rem;
  flex-direction: column;
  align-items: center;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  flex-wrap: wrap; /* Added this line to allow wrapping */

  @media (max-width: 56rem) {
    gap: 0rem;
  }
`;

const PricingDiscount = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.lending};
  margin: 0 0;
  text-align: left;
`;
const Content = styled.div`
  color: ${({ theme }) => theme.colors.white};
`;

const PricingCard = ({ icon, product, tier, description, price, duration, ctaText, ctaLink, saved }) => {
  return (
    <Card>
      <PlanContainer>
        <Icon src={`${icon}`} alt="Plan Icon" />
        <TitleDescriptionContainer>
          <Product>{product}</Product>
          <Tier>{tier}</Tier>
        </TitleDescriptionContainer>
      </PlanContainer>
      <Description dangerouslySetInnerHTML={{ __html: description }} />
      <PriceContainer>
        <PricingContainer>
          <Price>{price}</Price>
          <Duration>{duration}</Duration>
        </PricingContainer>
        <PricingDiscount>{saved}</PricingDiscount>
      </PriceContainer>
      {ctaText && ctaLink && (
        <CTAButtonContainer>
          <CTAButton text={ctaText} transparent full onClick={() => window.location.href = ctaLink} />
        </CTAButtonContainer>
      )}
    </Card>
  );
};

// Define the PricingCardsContainer styled component
export const PricingCardsContainer = styled.div`
  display: flex;
  justify-content: center; /* Center the container horizontally */
  align-items: flex-start;
  padding: 1.5rem;
  margin-top: 1rem;
  gap: 1rem;
  flex-wrap: wrap; /* Allow items to wrap onto multiple lines */
  margin-left: auto;
  margin-right: auto; /* Center the container within its parent */
  min-width: 1400px;

  @media (max-width: 1300px) {
    gap: 1.6rem;
    min-width: 800px;
  }
  @media (max-width: 700px) {
    max-width: 500px;
    min-width: 300px;
    flex-direction: column;
    align-items: center;
  }
`;

const PricingSection = ({ productType }) => {
  const { t } = useTranslation();
  const [activeDuration, setActiveDuration] = useState('Monthly');

  const LeaderboardPricingData = {
    Monthly: [
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_white.png`,
        product: t('Pricing_Leaderboard'),
        tier: t('Tier_White'),
        description: t('Leaderboard_White_Descr'),
        price: t('Announce'),
        // price: t('Leaderboard_White_Pricing_Monthly'),
        // duration: t('Pricing_Monthly'),
        // ctaText: t('CTA_Start'),
        // ctaLink: "https://example.com/subscribe/basic"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_pro.png`,
        product: t('Pricing_Leaderboard'),
        tier: t('Tier_Pro'),
        description: t('Leaderboard_Pro_Descr'),
        price: t('Announce'),
        // price: t('Leaderboard_Pro_Pricing_Monthly'),
        // duration: t('Pricing_Monthly'),
        // ctaText: t('CTA_Start'),
        // ctaLink: "https://example.com/subscribe/premium"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_max.png`,
        product: t('Pricing_Leaderboard'),
        tier: t('Tier_ProMax'),
        description: t('Leaderboard_ProMax_Descr'),
        price: t('Announce'),
        // price: t('Leaderboard_ProMax_Pricing_Monthly'),
        // duration: t('Pricing_Monthly'),
        // ctaText: t('CTA_Start'),
        // ctaLink: "https://example.com/subscribe/pro"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_vip.png`,
        product: t('Pricing_Leaderboard'),
        tier: t('Tier_VIP'),
        description: t('Leaderboard_VIP_Descr'),
        price: t('Announce'),
        // price: t('VIP_Pricing_Descrp'),
        // ctaText: t('CTA_Enquiry'),
        // ctaLink: "https://t.me/BAT_CustomerService"
      }
    ],
    SemiAnnualy: [
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_white.png`,
        product: t('Pricing_Leaderboard'),
        tier: t('Tier_White'),
        description: t('Leaderboard_White_Descr'),
        price: t('Announce'),
        // price: t('Leaderboard_White_Pricing_SemiAnnualy'),
        // duration: t('Pricing_SemiAnnualy'),
        // ctaText: t('CTA_Subscribe'),
        // ctaLink: "https://example.com/subscribe/basic"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_pro.png`,
        product: t('Pricing_Leaderboard'),
        tier: t('Tier_Pro'),
        description: t('Leaderboard_Pro_Descr'),
        price: t('Announce'),
        // price: t('Leaderboard_Pro_Pricing_SemiAnnualy'),
        // duration: t('Pricing_SemiAnnualy'),
        // ctaText: t('CTA_Subscribe'),
        // ctaLink: "https://example.com/subscribe/premium"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_max.png`,
        product: t('Pricing_Leaderboard'),
        tier: t('Tier_ProMax'),
        description: t('Leaderboard_ProMax_Descr'),
        price: t('Announce'),
        // price: t('Leaderboard_ProMax_Pricing_SemiAnnualy'),
        // duration: t('Pricing_SemiAnnualy'),
        // ctaText: t('CTA_Subscribe'),
        // ctaLink: "https://example.com/subscribe/pro"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_vip.png`,
        product: t('Pricing_Leaderboard'),
        tier: t('Tier_VIP'),
        description: t('Leaderboard_VIP_Descr'),
        price: t('Announce'),
        // price: t('VIP_Pricing_Descrp'),
        // ctaText: t('CTA_Enquiry'),
        // ctaLink: "https://t.me/BAT_CustomerService"
      }
    ],
    Annually: [
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_white.png`,
        product: t('Pricing_Leaderboard'),
        tier: t('Tier_White'),
        description: t('Leaderboard_White_Descr'),
        price: t('Announce'),
        // price: t('Leaderboard_White_Pricing_Annually'),
        // duration: t('Pricing_Annually'),
        //ctaText: t('CTA_Subscribe'),
        //ctaLink: "https://example.com/subscribe/basic"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_pro.png`,
        product: t('Pricing_Leaderboard'),
        tier: t('Tier_Pro'),
        description: t('Leaderboard_Pro_Descr'),
        price: t('Announce'),
        // price: t('Leaderboard_Pro_Pricing_Annually'),
        // duration: t('Pricing_Annually'),
        //ctaText: t('CTA_Subscribe'),
        //ctaLink: "https://example.com/subscribe/premium"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_max.png`,
        product: t('Pricing_Leaderboard'),
        tier: t('Tier_ProMax'),
        description: t('Leaderboard_ProMax_Descr'),
        price: t('Announce'),
        // price: t('Leaderboard_ProMax_Pricing_Annually'),
        // duration: t('Pricing_Annually'),
        //ctaText: t('CTA_Subscribe'),
        //ctaLink: "https://example.com/subscribe/pro"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_vip.png`,
        product: t('Pricing_Leaderboard'),
        tier: t('Tier_VIP'),
        description: t('Leaderboard_VIP_Descr'),
        price: t('Announce'),
        // price: t('VIP_Pricing_Descrp'),
        // ctaText: t('CTA_Enquiry'),
        // ctaLink: "https://example.com/subscribe/vip"
      }
    ]
  };
  const LendingPricingData = {
    Monthly: [
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_white.png`,
        product: t('Pricing_Lending'),
        tier: t('Tier_White'),
        description: t('Lending_White_Descr'),
        price: t('Lending_White_Pricing_Monthly'),
        duration: t('Pricing_Seasonally'),
        // ctaText: t('CTA_Start'),
        // ctaLink: "https://example.com/subscribe/basic"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_pro.png`,
        product: t('Pricing_Lending'),
        tier: t('Tier_Pro'),
        description: t('Lending_Pro_Descr'),
        price: t('Lending_Pro_Pricing_Monthly'),
        duration: t('Pricing_Seasonally'),
        // ctaText: t('CTA_Start'),
        // ctaLink: "https://example.com/subscribe/premium"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_max.png`,
        product: t('Pricing_Lending'),
        tier: t('Tier_ProMax'),
        description: t('Lending_ProMax_Descr'),
        price: t('Lending_ProMax_Pricing_Monthly'),
        duration: t('Pricing_Seasonally'),
        // ctaText: t('CTA_Start'),
        // ctaLink: "https://example.com/subscribe/pro"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_vip.png`,
        product: t('Pricing_Lending'),
        tier: t('Tier_VIP'),
        description: t('Lending_VIP_Descr'),
        price: t('VIP_Pricing_Descrp'),
        // ctaText: t('CTA_Enquiry'),
        // ctaLink: "https://t.me/BAT_CustomerService"
      }
    ],
    SemiAnnualy: [
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_white.png`,
        product: t('Pricing_Lending'),
        tier: t('Tier_White'),
        description: t('Lending_White_Descr'),
        price: t('Lending_White_Pricing_SemiAnnualy'),
        duration: t('Pricing_SemiAnnualy'),
        // ctaText: t('CTA_Subscribe'),
        // ctaLink: "https://example.com/subscribe/basic"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_pro.png`,
        product: t('Pricing_Lending'),
        tier: t('Tier_Pro'),
        description: t('Lending_Pro_Descr'),
        price: t('Lending_Pro_Pricing_SemiAnnualy'),
        duration: t('Pricing_SemiAnnualy'),
        // ctaText: t('CTA_Subscribe'),
        // ctaLink: "https://example.com/subscribe/premium"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_max.png`,
        product: t('Pricing_Lending'),
        tier: t('Tier_ProMax'),
        description: t('Lending_ProMax_Descr'),
        price: t('Lending_ProMax_Pricing_SemiAnnualy'),
        duration: t('Pricing_SemiAnnualy'),
        // ctaText: t('CTA_Subscribe'),
        // ctaLink: "https://example.com/subscribe/pro"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_vip.png`,
        product: t('Pricing_Lending'),
        tier: t('Tier_VIP'),
        description: t('Lending_VIP_Descr'),
        price: t('VIP_Pricing_Descrp'),
        // ctaText: t('CTA_Enquiry'),
        // ctaLink: "https://t.me/BAT_CustomerService"
      }
    ],
    Annually: [
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_white.png`,
        product: t('Pricing_Lending'),
        tier: t('Tier_White'),
        description: t('Lending_White_Descr'),
        price: t('Lending_White_Pricing_Annually'),
        duration: t('Pricing_Annually'),
        saved: t('Lending_White_Save_Annually'),
        //ctaText: t('CTA_Subscribe'),
        //ctaLink: "https://example.com/subscribe/basic"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_pro.png`,
        product: t('Pricing_Lending'),
        tier: t('Tier_Pro'),
        description: t('Lending_Pro_Descr'),
        price: t('Lending_Pro_Pricing_Annually'),
        duration: t('Pricing_Annually'),
        saved: t('Lending_Pro_Save_Annually'),
        //ctaText: t('CTA_Subscribe'),
        //ctaLink: "https://example.com/subscribe/premium"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_max.png`,
        product: t('Pricing_Lending'),
        tier: t('Tier_ProMax'),
        description: t('Lending_ProMax_Descr'),
        price: t('Lending_ProMax_Pricing_Annually'),
        duration: t('Pricing_Annually'),
        saved: t('Lending_ProMax_Save_Annually'),
        //ctaText: t('CTA_Subscribe'),
        //ctaLink: "https://example.com/subscribe/pro"
      },
      {
        icon: `${process.env.PUBLIC_URL}/img/pricing_vip.png`,
        product: t('Pricing_Lending'),
        tier: t('Tier_VIP'),
        description: t('Lending_VIP_Descr'),
        price: t('VIP_Pricing_Descrp'),
        saved: t('VIP_Save'),
        // ctaText: t('CTA_Enquiry'),
        // ctaLink: "https://t.me/BAT_CustomerService"
      }
    ]
  };

  const renderPricingCards = () => {
    const pricingData = productType === 'Leaderboard' ? LeaderboardPricingData : LendingPricingData;
    return pricingData[activeDuration].map((plan, index) => (
      <PricingCard
        key={index}
        icon={plan.icon}
        product={plan.product}
        tier={plan.tier}
        description={plan.description}
        price={plan.price}
        duration={plan.duration}
        ctaText={plan.ctaText}
        ctaLink={plan.ctaLink}
        saved={plan.saved}
      />
    ));
  };

  return (
    <Wrapper>
      <Container>
        <ButtonRow>
          <ButtonComponent
            text={t('Pricing_Seasonally')}
            transparent
            active={activeDuration === 'Monthly'}
            onClick={() => setActiveDuration('Monthly')}
          />
          <ButtonComponent
            text={t('Pricing_SemiAnnualy')}
            transparent
            active={activeDuration === 'SemiAnnualy'}
            onClick={() => setActiveDuration('SemiAnnualy')}
          />
          <ButtonComponent
            text={t('Pricing_Annually')}
            transparent
            active={activeDuration === 'Annually'}
            onClick={() => setActiveDuration('Annually')}
          />
        </ButtonRow>
        <Content>
          <PricingCardsContainer>
            {renderPricingCards()}
          </PricingCardsContainer>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default PricingSection;