import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ButtonComponent from './Button';
import axios from 'axios';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 0px 3rem;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 50rem;
  height: 36rem;
  flex-shrink: 0;
  border-radius: 1.5rem;
  flex-direction: column;
  align-items: center;

  @media (max-width: 70rem) {
    height: auto;
    align-items: center;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3rem;
  margin-top: 0;
`;

const YearSelector = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
`;

const YearInfo = styled.div`
  color: ${({ theme }) => theme.colors.white40};
  margin-top: 0.5rem;
  font-size: 0.8rem;
`;

const Content = styled.div`
  padding-top: 4rem;
  color: ${({ theme }) => theme.colors.white};
`;

const PnlContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 2rem;

  @media (max-width: 70rem) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const PnlImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const PnlImage = styled.img`
  max-width: 20rem;

  @media (max-width: 86rem) {
    width: 90%;
  }
  @media (max-width: 70rem) {
    width: 100%;
  }
`;

const Caption = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.white60};
  margin-top: 0.8rem;
  margin-bottom: 1.5rem;
`;

const ReviewContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  gap: 2rem;

  @media (max-width: 70rem) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

const ReviewImage = styled.img`
  max-height: 32rem;

  @media (max-width: 70rem) {
    width: 100%;
  }
`;

const UserReviewSection = () => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState('PNL');
  const [selectedYear, setSelectedYear] = useState('2024');
  const [pnlData, setPnlData] = useState({});
  const [yearInfo, setYearInfo] = useState({});

  useEffect(() => {
    axios.get(`${process.env.PUBLIC_URL}/IntradayPnl.json`)
      .then(response => {
        setPnlData(response.data.pnlData);
        setYearInfo(response.data.yearInfo);
      })
      .catch(error => {
        console.error('Error fetching PNL data:', error);
      });
  }, []);

  const renderContent = () => {
    switch (activePage) {
      case 'PNL':
        return (
          <PnlContainer>
            {pnlData[selectedYear] && pnlData[selectedYear].map((item, index) => (
              <PnlImageWrapper key={index}>
                <PnlImage src={item.src} alt={`PNL ${index + 1}`} />
                <Caption>{item.caption}</Caption>
              </PnlImageWrapper>
            ))}
          </PnlContainer>
        );
      case 'Review':
        return (
          <ReviewContainer>
            <ReviewImage src={`${process.env.PUBLIC_URL}/img/intraday/review1.png`} alt="Review 1" />
            <ReviewImage src={`${process.env.PUBLIC_URL}/img/intraday/review2.png`} alt="Review 2" />
            <ReviewImage src={`${process.env.PUBLIC_URL}/img/intraday/review3.png`} alt="Review 3" />
          </ReviewContainer>
        );
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <Container>
        <ButtonRow>
          <ButtonComponent
            text={t('Intraday_Customer_PNL')}
            transparent
            active={activePage === 'PNL'}
            onClick={() => setActivePage('PNL')}
          />
          <ButtonComponent
            text={t('Intraday_Customer_Review')}
            transparent
            active={activePage === 'Review'}
            onClick={() => setActivePage('Review')}
          />
        </ButtonRow>
        {activePage === 'PNL' && (
          <>
            <YearSelector>
              {Object.keys(pnlData).map((year) => (
                <ButtonComponent
                  key={year}
                  text={year}
                  transparent
                  active={selectedYear === year}
                  onClick={() => setSelectedYear(year)}
                />
              ))}
            </YearSelector>
            <YearInfo>{t(yearInfo[selectedYear])}</YearInfo>
          </>
        )}
        <Content>
          {renderContent()}
        </Content>
      </Container>
    </Wrapper>
  );
};

export default UserReviewSection;