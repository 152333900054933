import React from 'react';
import { useTranslation } from 'react-i18next';
import GeneralSection from '../components/GeneralSection'; // Import the GeneralSection component
import { HeroSection, HeroHeading, Subheading, StatisticsContainer, StatisticItem, StatisticText, StatisticIcon, StatisticNumber, StatisticDescription, HeroImage } from '../components/HeroComponents'; // Import styled components
import ProductCard, { DefaultHighlightItem, ProductContainer, ProductLendingHighlight3, ProductLeaderboardHighlight3} from '../components/ProductCard'; // Import the custom highlight item
import { ExchangeCard, ExchangeContainer } from '../components/ExchangeCard'; // Import the ExchangeCard and ExchangeContainer components
import AboutUsSection from '../components/AboutUs'; // Import the AboutUsSection component

const Home = () => {
  const { t } = useTranslation();
  return (
    <div>
      <HeroSection>
        <div>
          <HeroHeading>{t('HeroSection_Heading')}</HeroHeading>
          <Subheading>{t('HeroSection_Subheading')}</Subheading>
          <StatisticsContainer>
            <StatisticItem>
              <StatisticIcon src={`${process.env.PUBLIC_URL}/img/Icon_auto.png`} alt="Auto Icon" />
              <StatisticText>
                <StatisticDescription>{t('HeroSection_AutoTrading')}</StatisticDescription>
                <StatisticNumber>{t('HeroSection_AutoTrading_Stat')}</StatisticNumber>
              </StatisticText>
            </StatisticItem>
            <StatisticItem>
              <StatisticIcon src={`${process.env.PUBLIC_URL}/img/Icon_time.png`} alt="Time Icon" />
              <StatisticText>
                <StatisticDescription>{t('HeroSection_SaveTime')}</StatisticDescription>
                <StatisticNumber>{t('HeroSection_SaveTime_Stat')}</StatisticNumber>
              </StatisticText>
            </StatisticItem>
          </StatisticsContainer>
        </div>
        <HeroImage src={`${process.env.PUBLIC_URL}/img/hero_image.svg`} alt="Hero Image" />
      </HeroSection>
      <GeneralSection heading={t('ProductsSection_Heading')} align="center">
        <ProductContainer>
          <ProductCard
            title={t('ProductIntraday_Title')}
            description={t('ProductIntraday_Descr')}
            ctaText={t('CTA_LearnMore')}
            ctaLink={`${process.env.PUBLIC_URL}/products/intraday`}
          >
            <DefaultHighlightItem text={t('ProductIntraday_Highlight1')} />
            <DefaultHighlightItem text={t('ProductIntraday_Highlight2')} />
            <DefaultHighlightItem text={t('ProductIntraday_Highlight3')} />
          </ProductCard>
          <ProductCard
            title={t('ProductLeaderboard_Title')}
            description={t('ProductLeaderboard_Descr')}
            ctaText={t('CTA_LearnMore')}
            ctaLink={`${process.env.PUBLIC_URL}/products/leaderboard`}
          >
            <DefaultHighlightItem text={t('ProductLeaderboard_Highlight1')} />
            <DefaultHighlightItem text={t('ProductLeaderboard_Highlight2')} />
            <ProductLeaderboardHighlight3 />
            <DefaultHighlightItem text={t('ProductLeaderboard_Highlight4')} />
          </ProductCard>
          <ProductCard
            title={t('ProductLending_Title')}
            description={t('ProductLending_Descr')}
            ctaText={t('CTA_LearnMore')}
            ctaLink={`${process.env.PUBLIC_URL}/products/lendingpro`}
          >
            <DefaultHighlightItem text={t('ProductLending_Highlight1')} />
            <DefaultHighlightItem text={t('ProductLending_Highlight2')} />
            <ProductLendingHighlight3 />
          </ProductCard>
        </ProductContainer>
      </GeneralSection>
      <GeneralSection heading={t('SupportPlatformSection_Heading')} align="center">
        <ExchangeContainer>
            <ExchangeCard logoSrc={`${process.env.PUBLIC_URL}/img/binance.svg`} altText="Binance" />
            <ExchangeCard logoSrc={`${process.env.PUBLIC_URL}/img/bybit.svg`} altText="Bybit" />
            <ExchangeCard logoSrc={`${process.env.PUBLIC_URL}/img/okx.svg`} altText="OKX" />
            <ExchangeCard logoSrc={`${process.env.PUBLIC_URL}/img/bitfinex.svg`} altText="Bitfinex" />
        </ExchangeContainer>
      </GeneralSection>
      <GeneralSection heading={t('AboutUsSection_Heading')} align="center">
        <AboutUsSection />
      </GeneralSection>
      
    </div>
    
  );
};

export default Home;