import React, { useState } from 'react';
import styled from 'styled-components';

export const FaqSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center vertically */
  width: 100%;
  max-width: 90rem;
  gap: 4rem;
  margin: 0 auto; /* Center horizontally */
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  max-width: 60rem;
  margin: 0 auto; /* Center horizontally */
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  align-self: flex-start;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.main};

  @media (max-width: 48rem) {
    font-size: 1.2rem;
  }
`;

const QnaList = styled.div`
  flex-direction: column;
  width: 100%;
`;

const QuestionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 0;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  border-top: 0.06rem solid var(--Border-Subtle, #393953);
  border-bottom: ${({ $isActive }) => ($isActive ? 'none' : '0.06rem solid var(--Border-Subtle, #393953)')};
  background: transparent;
`;

const Question = styled.div`
  font-size: 1.2rem;
  color: ${({ $isActive, theme }) => ($isActive ? theme.colors.lending : theme.colors.white)};
  flex-grow: 1;
  text-align: left;

  @media (max-width: 48rem) {
    font-size: 1rem;
  }
`;

const Icon = styled.img`
  width: 1.6rem;
  height: auto;

  @media (max-width: 48rem) {
    width: 1.2rem;
  }
`;

const Answer = styled.div`
  font-size: 0.9rem;
  padding: 0;
  padding-bottom: 1.4rem;
  padding-right: 1.6rem;
  text-align: left;
  white-space: pre-line; /* Handle \n as line break */
  color: ${({ theme }) => theme.colors.white70};
`;

const ListDescr = styled.ol`
  padding-left: 0;
  list-style-position: inside;
`;

const NumberedDesct = styled.li`
  margin-bottom: 0.5rem;
`;

const ToggleItem = styled.div`
  margin-top: 0.5rem;
  padding-left: 1rem;
  border-left: 2px solid ${({ theme }) => theme.colors.main};
`;

const ToggleItemTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.white};
`;

const ToggleItemDesc = styled.p`
  white-space: pre-line; /* Handle \n as line break */
  font-size: 0.8rem;
  margin: 0;
  color: ${({ theme }) => theme.colors.white70};
`;

const defaultIcon = `${process.env.PUBLIC_URL}/img/icon-down.svg`;
const activeIcon = `${process.env.PUBLIC_URL}/img/icon-cross.svg`;

export const FaqItem = ({ question, answer, isActive, onClick, toggles }) => (
  <div>
    <QuestionRow $isActive={isActive} onClick={onClick}>
      <Question $isActive={isActive}>{question}</Question>
      <Icon src={isActive ? activeIcon : defaultIcon} alt="icon" />
    </QuestionRow>
    {isActive && (
      <Answer>
        {Array.isArray(answer) ? (
          <ListDescr>
            {answer.map((item, index) => (
              <NumberedDesct key={index} dangerouslySetInnerHTML={{ __html: item }} />
            ))}
          </ListDescr>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: answer }} />
        )}
        {toggles && toggles.map((toggle, index) => (
          <ToggleItem key={index}>
            <ToggleItemTitle>{toggle.title}</ToggleItemTitle>
            <ToggleItemDesc>{toggle.description}</ToggleItemDesc>
          </ToggleItem>
        ))}
      </Answer>
    )}
  </div>
);

const FaqContainer = ({ title, children }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <InnerContainer>
      <Title>{title}</Title>
      <QnaList>
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, {
            isActive: activeIndex === index,
            onClick: () => handleToggle(index),
          })
        )}
      </QnaList>
    </InnerContainer>
  );
};

export default FaqContainer;