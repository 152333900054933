import React from 'react';
import { useTranslation } from 'react-i18next';
import GeneralSection from '../components/GeneralSection';
import StrategyPerformanceExplorer from '../components/StrategyPerformanceExplorer';
import ButtonComponent from '../components/Button';

const IntradayPerformance = () => {
  const { t } = useTranslation();
  const message = encodeURIComponent(t('CTA_TG_Default'));
  const telegramLink = `https://t.me/BAT_CustomerService?text=${message}`;
  return (
    <>
      <GeneralSection subheading={t('Intraday_Performance_Subheading')} heading={t('Intraday_Performance_Heading')} align="center">
        <StrategyPerformanceExplorer />
      </GeneralSection>
      <GeneralSection align="center">
      </GeneralSection>
      <ButtonComponent text={t('CTA_ContactUs')} large transparent icon={`/arrow-right.svg`} onClick={() => window.open(telegramLink, '_blank')} />
    </>
  );
};

export default IntradayPerformance;