import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const CompareContainer = styled.div`
  display: flex;
  padding: 1.5rem;
  justify-content: center;
  align-items: stretch; /* Ensure all cards have the same height */
  gap: 3.5rem;
  flex-wrap: wrap;
  padding: 0px 3rem;
`;

const CompareProduct = styled.div`
  display: flex;
  align-items: center;
  width: 15rem;
  padding: 3rem 3rem;
  gap: 3rem; /* Gap between main areas */
  border-radius: 24px;
  background: ${({ $bgColor }) => $bgColor || 'rgba(244, 244, 244, 0.04)'};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(1px);
  max-width: 36%;
  flex-grow: 1; /* Ensure all cards have the same height */ 
  opacity: ${({ $opacity }) => $opacity || 1}; /* Set opacity based on prop */

  @media (max-width: 70rem) { 
    min-width: 50%;
    max-width: 100%;
  }
  
  @media (max-width: 46rem) { 
    max-width: 100%;
    padding: 3rem 1.5rem;
    gap: 1.6rem; /* Gap between main areas */
  }

  @media (max-width: 30rem) {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: stretch;
    gap: 1.5rem; /* Gap between main areas */
    padding: 1.6rem 1.2rem;
`;

const Title = styled.h3`
  font-size: 2.4rem; /* Bigger font size */
  text-align: center; /* Centered text */
  margin: 0;
  color: ${({ theme, $isMain }) => ($isMain ? theme.colors.main : theme.colors.white40)};

  @media (max-width: 46rem) {
    font-size: 2rem; /* Adjust font size for mobile devices */
  }
`;

const CompareHighlightsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 18px; /* Gap between each highlight item */
  flex-grow: 1; /* Allow the list to grow and take available space */
`;

const CompareHighlightItem = styled.li`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const CompareHighlightIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const CompareHighlightTextHeading = styled.span`
  font-size: 1rem;
  text-align: left;
  font-weight: 600;
  white-space: pre-line; /* Handle \n as line break */
  color: ${({ theme }) => theme.colors.white};
  opacity: ${({ $opacity }) => $opacity || 1}; /* Set opacity based on prop */

  @media (max-width: 46rem) {
    font-size: 0.8rem; /* Adjust font size for mobile devices */
  }
`;

const CompareHighlightTextDescr = styled.span`
  font-size: 0.8rem;
  text-align: left;
  white-space: pre-line; /* Handle \n as line break */
  color: ${({ theme }) => theme.colors.white60};
  opacity: ${({ $opacity }) => $opacity || 1}; /* Set opacity based on prop */

  @media (max-width: 46rem) {  
    font-size: 0.7rem; /* Adjust font size for mobile devices */
  }
`;

const CompareTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.2rem;
`;

export const DefaultCompareHighlightItem = ({ heading, description }) => (
  <CompareHighlightItem>
    <CompareHighlightIcon src={`${process.env.PUBLIC_URL}/img/default-icon.svg`} alt="Highlight Icon" />
    <CompareTextContainer>
        <CompareHighlightTextHeading>{heading}</CompareHighlightTextHeading>
        <CompareHighlightTextDescr>{description}</CompareHighlightTextDescr>
    </CompareTextContainer>
  </CompareHighlightItem>
);

export const OtherCompareHighlightItem = ({ heading, description }) => (
  <CompareHighlightItem>
    <CompareHighlightIcon src={`${process.env.PUBLIC_URL}/img/alert-icon.svg`} alt="Alert Icon" />
    <CompareTextContainer>
        <CompareHighlightTextHeading $opacity={0.7}>{heading}</CompareHighlightTextHeading>
        <CompareHighlightTextDescr $opacity={0.8}>{description}</CompareHighlightTextDescr>
    </CompareTextContainer>
  </CompareHighlightItem>
);

const CompareProductCard = ({ title, children, isMain, opacity, bgColor }) => {
    return (
      <CompareProduct $opacity={opacity} $bgColor={bgColor}>
        <Title $isMain={isMain}>{title}</Title>
        <CompareHighlightsList>
          {children}
        </CompareHighlightsList>
      </CompareProduct>
    );
  };

const UnderlinedText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.whiteS};
  font-weight: 600;
`;

const UnderlinedText2 = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white50};
  opacity: ${({ $opacity }) => $opacity || 1}; /* Set opacity based on prop */
`;

const Tooltip = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  max-width: 13rem;
  font-size: 0.8rem;
  opacity: 1.0;
`;

export const OtherCompareHighlightItemWithTooltip = ({ heading, description, tooltipText }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleTooltipToggle = () => {
    setTooltipVisible(!tooltipVisible);
  };

  return (
    <CompareHighlightItem>
      <CompareHighlightIcon src={`${process.env.PUBLIC_URL}/img/alert-icon.svg`} alt="Alert Icon" />
      <CompareTextContainer>
        <CompareHighlightTextHeading>
          <UnderlinedText onClick={handleTooltipToggle}>{heading}</UnderlinedText>
          {tooltipVisible && (
            <Tooltip visible={tooltipVisible}>
              {tooltipText}
            </Tooltip>
          )}
        </CompareHighlightTextHeading>
        <CompareHighlightTextDescr $opacity={0.8}>{description}</CompareHighlightTextDescr>
      </CompareTextContainer>
    </CompareHighlightItem>
  );
};

export const OtherCompareHighlightItemWithTooltip2 = ({ heading, description, tooltipText }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleTooltipToggle = () => {
    setTooltipVisible(!tooltipVisible);
  };

  return (
    <CompareHighlightItem>
      <CompareHighlightIcon src={`${process.env.PUBLIC_URL}/img/alert-icon.svg`} alt="Alert Icon" />
      <CompareTextContainer>
        <CompareHighlightTextHeading $opacity={0.8}>{heading}</CompareHighlightTextHeading>
        <CompareHighlightTextDescr>
          <UnderlinedText2 onClick={handleTooltipToggle}>{description}</UnderlinedText2>
          {tooltipVisible && (
            <Tooltip visible={tooltipVisible}>
              {tooltipText}
            </Tooltip>
          )}
        </CompareHighlightTextDescr>
      </CompareTextContainer>
    </CompareHighlightItem>
  );
};

export const LeaderboardCompareOther3 = () => {
  const { t } = useTranslation();

  return (
    <OtherCompareHighlightItemWithTooltip
      heading={t('Leaderboard_Others_3_Title')}
      tooltipText={t('Leaderboard_Others_3_Suppl')}
    />
  );
};

export const LeaderboardCompareOther4 = () => {
  const { t } = useTranslation();

  return (
    <OtherCompareHighlightItemWithTooltip2
      heading={t('Leaderboard_Others_4_Title')}
      description={t('Leaderboard_Others_4_Descr')}
      tooltipText={t('Leaderboard_Others_4_Suppl')}
    />
  );
};

export default CompareProductCard;