import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ButtonComponent from './Button';
import NavigationDropdown from './NavigationDropdown';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  margin: 0;
  display: grid;
  align-items: center;
  grid-template: "logo navigation ctas" 76px / auto 1fr auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 16px 24px; /* Adjust padding as needed */
  justify-content: center; /* Center the content */
  background-color:${({ theme }) => theme.colors.background}; /* Use theme color */

  @media (max-width: 82rem) {
    min-width: 100vw;
    grid-template: "logo cta-menu" 76px / auto 1fr;
    grid-gap: 0px;
    justify-content: space-between; /* Spread out items */
    padding-left: 16px; /* Adjust this value as needed */
    padding-right: 16px; /* Adjust this value as needed */
  }
`;

const Logo = styled.div`
  grid-area: logo;
  img {
    height: 42px; /* Adjust the height as needed */

    @media (min-width: 82rem) {
      margin-right: 100px; /* Add this line to increase spacing */
    }
    @media (max-width: 82rem) {
      height: 36px; /* Smaller logo for mobile */
      padding-left: 8px; /* Adjust this value as needed */
    }

    @media (max-width: 48rem) {
      height: 30px; /* Smaller logo for smaller mobile screens */
    }
  }
`;

const LighterText = styled.span`
  color: ${({ theme }) => theme.colors.white40}; /* Use a lighter color from the theme */
  font-size: 1.1rem; /* Smaller font size for labels */
  display: block; /* Ensure it takes full width */
  margin-left: 1rem; /* Indent to align with sub-items */
`;

const Nav = styled.nav`
  grid-area: navigation;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 24px; /* Add this line to increase spacing */

  ul {
    padding: 0;
    display: flex;
    gap: 1.6rem;
    list-style: none;

    @media (max-width: 82rem) {
      display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
      flex-direction: column; /* Change to column direction */
      justify-content: flex-start;
      position: absolute;
      top: 76px;
      left: 0;
      width: 100%; /* Ensure it takes full width */
      background-color: ${({ theme }) => theme.colors.background};
      padding: 16px;
      padding-top: 0; /* Remove top padding */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      text-align: left; /* Align items to the left */
      gap: 0.2rem;
    }

    @media (max-width: 48rem) {
      gap: 0rem;
    }
  }

  li {
    display: inline;
    font-size: 1rem; /* Larger font size for pages */
    color: ${({ theme }) => theme.colors.white}; /* White color for pages */

    @media (max-width: 82rem) {
      display: block;
    }
  }

  .section-item {
    @media (max-width: 82rem) {
      margin-top: 1rem; /* Add margin to separate sections */
  }

  .sub-item {
    margin-left: 1rem; /* Indent sub-items */
    margin-top: 0.5rem; /* Add margin to separate sub-items */
  }
`;

const CTAsContainer = styled.div`
  grid-area: ctas;
  display: flex;
  align-items: center;
  gap: 8px; /* Reduced gap */
  margin-left: 24px; /* Add this line to increase spacing */

  @media (max-width: 82rem) {
    grid-area: cta-menu;
    justify-content: flex-end;
  }
`;

// const GetStartedLink = styled(Link)`
//   text-decoration: underline;
//   font-size: 0.9rem;
//   margin-right: 10px; /* Add this line to increase spacing */

//   &:hover {
//     color: ${({ theme }) => theme.colors.main};
//   }
// `;

const LanguageButtonContainer = styled.div`
  text-align: right;
  order: 2; /* Ensure the language button is before the menu icon on small screens */

  @media (max-width: 82rem) {
    display: none; /* Hide the language button on mobile */
  }
)
`;

const CTAButtonContainer = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  order: 1; /* Ensure the CTA button is first */

  @media (max-width: 82rem) {
    justify-content: flex-end;
    gap: 16px;
    order: 1; /* Keep the CTA button first on small screens */
  }
`;

const MenuIcon = styled.div`
  display: none;
  cursor: pointer;
  order: 3; /* Ensure the menu icon is the last item on small screens */

  @media (max-width: 82rem) {
    display: block;
    order: 3; /* Ensure the menu icon is the last item on small screens */
  }

  img {
    height: 54px; /* Adjust the height as needed */

    @media (max-width: 82rem) {
      height: 40px; /* Smaller icon for mobile */

    }
  }
`;

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const message = encodeURIComponent(t('CTA_TG_Default'));
  const telegramLink = `https://t.me/BAT_CustomerService?text=${message}`;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'zh' : 'en';
    console.log(`Changing language from ${i18n.language} to ${newLanguage}`);
    i18n.changeLanguage(newLanguage)
      .then(() => console.log(`Language changed to ${newLanguage}`))
      .catch(err => console.error('Error changing language:', err));
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1312) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const intradayOptions = [
    // { label: t('Info'), link: 'https://bitcoinalgotraders.com/testing/products/intraday' },
    // { label: t('Performance'), link: 'https://bitcoinalgotraders.com/testing/products/intraday/performance' },
    { label: t('Info'), link: '/products/intraday' },
    { label: t('Performance'), link: '/products/intraday/performance' },
  ];
  const leaderboardOptions = [
    // { label: t('Info'), link: 'https://bitcoinalgotraders.com/testing/products/leaderboard' },
    { label: t('Info'), link: '/products/leaderboard' },
    // { label: t('Leaderboard White",'), link: '' },
  ];
  const lendingOptions = [
    { label: t('Info'), link: '/products/lendingpro' },
    // { label: t('Info'), link: 'https://bitcoinalgotraders.com/testing/products/lendingpro' },
    { label: t('Lending_App'), link: 'http://lendingpro.bitcoinalgotraders.com/' },
  ];
  
  const eventOptions = [
    { label: t('Bitguess'), link: 'https://bitcoinalgotraders.com/bitguess/' },
  ];

  return (
    <HeaderContainer>
      <Logo>
        <Link to="/" onClick={closeMenu}>
          <img src={`${process.env.PUBLIC_URL}/img/logo.png`} alt="Logo" />
        </Link>
      </Logo>

      <Nav $isOpen={isOpen}>
        <ul>
          <li className="section-item">
            <Link to="/" onClick={closeMenu}>
              <ButtonComponent text={t('Home')} transparent />
            </Link>
          </li>
          {isOpen ? (
            <>
              <li className="section-item">
                <LighterText>{t('Intraday')}</LighterText>
              </li>
              {intradayOptions.map(option => (
                <li key={option.label} className="sub-item">
                  <Link to={option.link} onClick={closeMenu}>
                    <ButtonComponent text={option.label} transparent />
                  </Link>
                </li>
              ))}
              <li className="section-item">
                <LighterText>{t('Leaderboard')}</LighterText>
              </li>
              {leaderboardOptions.map(option => (
                <li key={option.label} className="sub-item">
                  <Link to={option.link} onClick={closeMenu}>
                    <ButtonComponent text={option.label} transparent />
                  </Link>
                </li>
              ))}
              <li className="section-item">
                <LighterText>{t('LendingPro')}</LighterText>
              </li>
              {lendingOptions.map(option => (
                <li key={option.label} className="sub-item">
                  <Link to={option.link} onClick={closeMenu}>
                    <ButtonComponent text={option.label} transparent />
                  </Link>
                </li>
              ))}
              <li className="section-item">
                <LighterText>{t('Events')}</LighterText>
              </li>
              {eventOptions.map(option => (
                <li key={option.label} className="sub-item">
                  <a href={option.link} onClick={closeMenu}>
                    <ButtonComponent text={option.label} transparent />
                  </a>
                </li>
              ))}
            </>
          ) : (
            <>
              {/* <li>
                <NavigationDropdown label={t('Products')} options={productOptions} />
              </li> */}
              <li>
                <NavigationDropdown label={t('Intraday')} options={intradayOptions} />
              </li>
              <li>
                <NavigationDropdown label={t('Leaderboard')} options={leaderboardOptions} />
              </li>
              <li>
                <NavigationDropdown label={t('LendingPro')} options={lendingOptions} />
              </li>
              <li>
                <NavigationDropdown label={t('Events')} options={eventOptions} />
              </li>
            </>
          )}
          <li className="section-item">
            <Link to="/download" onClick={closeMenu}>
              <ButtonComponent text={t('Download')} transparent />
            </Link>
          </li>
          <li className="section-item">
            <Link to="/faq" onClick={closeMenu}>
              <ButtonComponent text={t('FAQ')} transparent />
            </Link>
          </li>
          {isOpen && (
            <li>
              <ButtonComponent text={i18n.language === 'en' ? 'ZH' : 'EN'} transparent lowStyle onClick={toggleLanguage} />
            </li>
          )}
        </ul>
      </Nav>

      <CTAsContainer>
        {/* <GetStartedLink to="/" onClick={closeMenu}>
          {t('Start')}
        </GetStartedLink> */}
        <CTAButtonContainer>
          <ButtonComponent text={t('CTA_ContactUs')} transparent newHoverStyle onClick={() => window.open(telegramLink, '_blank')} />
        </CTAButtonContainer>
        <LanguageButtonContainer>
          <ButtonComponent text={i18n.language === 'en' ? 'ZH' : 'EN'} transparent lowStyle onClick={toggleLanguage} />
        </LanguageButtonContainer>
        <MenuIcon onClick={toggleMenu}>
          <img src={`${process.env.PUBLIC_URL}/img/menu.svg`} alt="Menu Icon" />
        </MenuIcon>
      </CTAsContainer>
    </HeaderContainer>
  );
};

export default Navbar;