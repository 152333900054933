// src/theme.js
export const theme = {
  colors: {
    background: '#191919',
    white: '#E1E1E1',
    whiteS: '#A9A9A9',
    white85: 'rgba(244, 244, 244, 0.85)',
    white70: 'rgba(244, 244, 244, 0.7)',
    white60: 'rgba(244, 244, 244, 0.6)',
    white50: 'rgba(244, 244, 244, 0.5)',
    white40: 'rgba(244, 244, 244, 0.4)',
    main: '#BDD636',
    leaderboard: '#429A6D',
    lending: '#FFD300',
    sub: '#FF6300',
    intraday: '#325FA3',
    productDescr: '#616161',
    // Add other colors as needed
  },
};