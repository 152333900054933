import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import ChartComponent from './ChartComponent';
import ButtonComponent from './Button';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0px 3rem;
  max-width: 80rem; /* Set a maximum width */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items horizontally */

  @media (max-width: 70rem) {
    padding: 0px 3rem;
  }

  @media (max-width: 48rem) {
    align-items: center;
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4rem;
  width: 100%;
  justify-content: center;

  @media (max-width: 70rem) {
    flex-direction: column;
    align-items: center;
  }
`;

const ChartSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @media (max-width: 70rem) {
    min-width: 22rem;
    height: auto;
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start; /* Align controls to the left */
  margin-top: 2rem;

  @media (max-width: 48rem) {
    font-size: 0.8rem;
  }
`;

const ControlGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start; /* Align controls to the left */
`;

const HorizontalControlGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  max-width: 36rem; /* Limit the width of the controls */
  align-items: flex-start; /* Align controls to the left */
`;

const ChartContainer = styled.div`
  min-width: 42rem;

  @media (max-width: 70rem) {
    min-width: 100%;
    height: auto;
  }
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.white70};
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1.5rem; /* Increase the gap to add more spacing between buttons */
`;

const Input = styled.input`
  padding: 8px 16px;
  background: ${({ theme }) => 'rgba(100, 100, 100, 0.1)'};
  border-radius: 8px;
  border: none;
  color: ${({ theme }) => theme.colors.white70};
  &:focus {
    outline: none;
  }
  
  /* Customizing the placeholder text */
  &::placeholder {
    color: ${({ theme }) => theme.colors.white60};
  }

  /* Hiding the default calendar icon */
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: invert(1);
    opacity: 0.5;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${({ theme }) => theme.colors.white70};

  @media (max-width: 48rem) {
    font-size: 0.8rem;
  }
`;

const Checkbox = styled.input`
  accent-color: ${({ theme }) => theme.colors.main};
`;

const TableContainer = styled.div`
  width: 100%;

  @media (max-width: 70rem) {
    margin-top: 5rem;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
`;

const TableHeader = styled.th`
  background-color: transparent; /* Make the header background transparent */
  color: ${({ theme }) => theme.colors.white40};
  font-weight: 400;
  text-align: left;

  @media (max-width: 48rem) {
    font-size: 0.8rem;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.white10};
  }
`;

const TableCell = styled.td`
  text-align: left; /* Align text to the left */
  border: none; /* Remove cell borders */

  @media (max-width: 48rem) {
    font-size: 0.9rem;
  }
`;

const StrategyCell = styled(TableCell)`
  color: ${({ theme }) => theme.colors.main}; /* Set the strategy text color to main */
`;

const StrategyPerformanceExplorer = () => {
  const { t } = useTranslation();
  const [range, setRange] = useState('All');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedStrategies, setSelectedStrategies] = useState(['Strategy Z']);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [specialOptions, setSpecialOptions] = useState({});

  useEffect(() => {
    // Fetch strategies data from the JSON file
    fetch(`${process.env.PUBLIC_URL}/strategies.json`)
      .then(response => response.json())
      .then(data => {
        setStrategies(data.strategies);
        setSpecialOptions(data.specialOptions);
      });
  }, []);

  const strategyNameMap = strategies.reduce((map, strategy) => {
    const simplifiedName = strategy.replace('Strategy ', '');
    map[simplifiedName] = strategy;
    return map;
  }, {});

  useEffect(() => {
    // Fetch default data for Strategy Z on component mount
    // axios.post('http://localhost:8000/api.php', {
    axios.post('https://bitcoinalgotraders.com/backend/api.php', {
      strategies: ['Strategy Z'],
    }).then(response => {
      setData(response.data.graph_data);
      setTableData(response.data.table_return);
    });
  }, []);

  const handleRangeChange = (newRange) => {
    setRange(newRange);
  
    let startDate;
    const endDate = new Date().toISOString().split('T')[0]; // Current date
  
    switch (newRange) {
      case '1D':
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        break;
      case '1M':
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        break;
      case '1Y':
        startDate = new Date();
        startDate.setFullYear(startDate.getFullYear() - 1);
        break;
      default:
        startDate = null;
    }
  
    if (startDate) {
      startDate = startDate.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    }
  
    // Fetch data based on the selected range
    // axios.post('http://localhost:8000/api.php', {
    axios.post('https://bitcoinalgotraders.com/backend/api.php', {
      startDate,
      endDate,
      strategies: selectedStrategies,
    }).then(response => {
      setData(response.data.graph_data);
      setTableData(response.data.table_return);
    });
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === 'startDate') setStartDate(value);
    if (name === 'endDate') setEndDate(value);
  };

  const handleStrategyChange = (event) => {
    const { value, checked } = event.target;
    if (value === 'All') {
      if (checked) {
        setSelectedStrategies(strategies);
      } else {
        setSelectedStrategies([]);
      }
    } else {
      const originalName = strategyNameMap[value] || value;
      setSelectedStrategies(prev => {
        if (checked) {
          if (specialOptions[originalName]) {
            // Add all strategies in the special option
            return [...new Set([...prev, ...specialOptions[originalName]])];
          } else {
            return [...prev, originalName];
          }
        } else {
          if (specialOptions[originalName]) {
            // Remove all strategies in the special option
            return prev.filter(strategy => !specialOptions[originalName].includes(strategy));
          } else {
            return prev.filter(strategy => strategy !== originalName);
          }
        }
      });
    }
  };

  const handleSubmit = () => {
    // Fetch data from the database based on the selected options
    // axios.post('http://localhost:8000/api.php', {
    axios.post('https://bitcoinalgotraders.com/backend/api.php', {
      startDate,
      endDate,
      strategies: selectedStrategies,
    }).then(response => {
      // Assuming response.data is in the format required by ChartComponent
      setData(response.data.graph_data);
      setTableData(response.data.table_return);
    });
  };

  return (
    <Wrapper>
      <TopSection>
        <ChartSection>
          <ButtonGroup>
            {['1D', '1M', '1Y', 'All'].map(option => (
              <ButtonComponent
                key={option}
                text={option}
                active={range === option} // Set active state
                onClick={() => handleRangeChange(option)}
              >
                {option}
              </ButtonComponent>
            ))}
          </ButtonGroup>
          <ChartContainer>
            <ChartComponent data={data} />
          </ChartContainer>
        </ChartSection>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <TableHeader>{t('Table_Strategy')}</TableHeader>
                <TableHeader>{t('Table_TotalReturn')}</TableHeader>
                <TableHeader>{t('Table_SharpeRatio')}</TableHeader>
                <TableHeader>{t('Table_MDD')}</TableHeader>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <TableRow key={index}>
                  <StrategyCell>{row.name}</StrategyCell>
                  <TableCell>{(row.total_return * 100).toFixed(3)}%</TableCell>
                  <TableCell>{row.sharpe_ratio.toFixed(3)}</TableCell>
                  <TableCell>{(row.max_dd * 100).toFixed(3)}%</TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        <Controls>
          <HorizontalControlGroup>
            <ControlGroup>
              <Label>Start Date:</Label>
              <Input type="date" name="startDate" value={startDate} onChange={handleDateChange} />
            </ControlGroup>
            <ControlGroup>
              <Label>End Date:</Label>
              <Input type="date" name="endDate" value={endDate} onChange={handleDateChange} />
            </ControlGroup>
          </HorizontalControlGroup>
            <ControlGroup>
            <Label>Strategies:</Label>
            <CheckboxContainer>
              <HorizontalControlGroup>
                <CheckboxLabel>
                  <Checkbox type="checkbox" value="All" onChange={handleStrategyChange} /> All
                </CheckboxLabel>
                {Object.keys(specialOptions).map(option => (
                  <CheckboxLabel key={option}>
                    <Checkbox type="checkbox" value={option} onChange={handleStrategyChange} /> {option}
                  </CheckboxLabel>
                ))}
              </HorizontalControlGroup>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
                {strategies.map(strategy => {
                  const simplifiedName = strategy.replace('Strategy ', '');
                  return (
                    <CheckboxLabel key={strategy}>
                      <Checkbox type="checkbox" value={simplifiedName} checked={selectedStrategies.includes(strategy)} onChange={handleStrategyChange} /> {simplifiedName}
                    </CheckboxLabel>
                  );
                })}
              </div>
            </CheckboxContainer>
          </ControlGroup>
          <ButtonComponent text="Submit" transparent large onClick={handleSubmit}>
            Submit
          </ButtonComponent>
        </Controls>
        </TableContainer>
      </TopSection>
    </Wrapper>
  );
};

export default StrategyPerformanceExplorer;