import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ButtonComponent from './Button';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column; /* Ensure vertical layout */
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 3rem; /* Adjust the gap as needed */
`;

const Container = styled.div`
    width: 90%;
    min-height: 30rem;
    max-width: 65rem; /* Optional: Set a maximum width */
    height: 36rem;
    flex-shrink: 0;
    border-radius: 1.5rem;
    background: rgba(244, 244, 244, 0.05);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 70rem) {
        height: auto;
    }

    @media (max-width: 48rem) {
        max-width: 22rem; /* Optional: Set a maximum width */
    }
`;

const ButtonRow = styled.div`
    display: flex;
    padding: 0.75rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 1.55rem;
    margin-top: 2rem;
`;

const Content = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    color: ${({ theme }) => theme.colors.white};
    overflow-y: auto; /* Make the content scrollable */
`;

const BackgroundContainer = styled.div`
    width: 80%;
    flex-shrink: 0;
    display: flex;
    align-items: center; /* Align items horizontally */
    gap: 2rem; /* Adjust the gap as needed */
    padding-bottom: 2rem; /* Add padding to the bottom */

    @media (max-width: 70rem) {
        flex-direction: column; /* Change to vertical layout */
        align-items: center; /* Center align items */
    }
`;

const ScrollableImageContainer = styled.div`
  width: 60%;
  max-height: 92%; /* Ensure it takes the full height of the container */
  overflow-y: auto; /* Make the image container scrollable */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }

  &:hover::-webkit-scrollbar {
    display: block; /* Show scrollbar on hover */
  }
`;

const PnlImage = styled.img`
  max-width: 80%;
  height: auto;
  border-radius: 1rem; /* Add border radius to the image */

  @media (max-width: 70rem) {
    max-width: 100%; /* Adjust the width as needed */
  }
`;

const Image = styled.img`
    width: 40%;
    height: auto;

    @media (max-width: 70rem) {
        width: 60%; /* Adjust the width as needed */
    }
    @media (max-width: 48rem) {
        width: 80%; /* Adjust the width as needed */
}
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  flex-grow: 1; /* Ensure it takes the remaining space */
`;

const NameTitleContainer = styled.div`
  display: flex;
  align-items: baseline; /* Align items on the same line */
  gap: 0.5rem; /* Adjust the gap as needed */
`;

const FounderName = styled.div`
  font-size: 2.1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: 48rem) {
    font-size: 1.4rem; /* Adjust font size for small screens */
  }
`;

const FounderTitle = styled.div`
  font-size: 0.8rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.main};

  @media (max-width: 48rem) {
    font-size: 0.6rem; /* Adjust font size for small screens */
  }
`;

const HighlightList = styled.ul`
  list-style: disc;
  padding: 0;
  margin: 0;
  margin-left: 1.2rem; /* Indent the list */
  text-align: left;
`;

const HighlightItem = styled.li`
  font-size: 0.85rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white70};
  margin-bottom: 0.3rem; /* Add margin-bottom to increase spacing */

  @media (max-width: 48rem) {
    font-size: 0.7rem; /* Adjust font size for small screens */
  }
`;

const PrincipleContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 1rem 3rem;
  flex-wrap: wrap;
`;

const PrincipleHighlight = styled.div`
  width: 12rem;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-shrink: 0;
  flex-wrap: wrap;
  text-align: center;
`;

const PrincipleImage = styled.img`
  width: 25%;
  height: auto;
`;

const PrincipleTitle = styled.h3`
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
`;

const PrincipleDescription = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white60};

  @media (max-width: 48rem) {
    font-size: 0.7rem; /* Adjust font size for small screens */
  }
`;

const AboutUsSection = () => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState('Background');
  const message = encodeURIComponent(t('CTA_TG_Default'));
  const telegramLink = `https://t.me/BAT_CustomerService?text=${message}`;

  const renderContent = () => {
    switch (activePage) {
      case 'Background':
        return (
          <BackgroundContainer>
            <Image src={`${process.env.PUBLIC_URL}/img/founder.svg`} alt="Founder" />
            <TextContainer>
              <NameTitleContainer>
                <FounderName>{t('Background_Founder')}</FounderName>
                <FounderTitle>{t('Background_Title')}</FounderTitle>
              </NameTitleContainer>
              <HighlightList>
                <HighlightItem>{t('Founder_Highlight1')}</HighlightItem>
                <HighlightItem>{t('Founder_Highlight2')}</HighlightItem>
                <HighlightItem>{t('Founder_Highlight3')}</HighlightItem>
                <HighlightItem>{t('Founder_Highlight4')}</HighlightItem>
                <HighlightItem>{t('Founder_Highlight5')}</HighlightItem>
              </HighlightList>
            </TextContainer>
          </BackgroundContainer>
        );
      case 'PNL':
        return (
          <ScrollableImageContainer>
            <PnlImage src={`${process.env.PUBLIC_URL}/img/Batpnl.png`} alt="PNL" />
          </ScrollableImageContainer>
        );
      case 'Principle':
        return (
          <PrincipleContainer>
            <PrincipleHighlight>
              <PrincipleImage src={`${process.env.PUBLIC_URL}/img/script.png`} alt="Principle 1" />
              <PrincipleTitle>{t('Principal_Highlight1_Title')}</PrincipleTitle>
              <PrincipleDescription>{t('Principal_Highlight1_Descr')}</PrincipleDescription>
            </PrincipleHighlight>
            <PrincipleHighlight>
              <PrincipleImage src={`${process.env.PUBLIC_URL}/img/clock.png`} alt="Principle 2" />
              <PrincipleTitle>{t('Principal_Highlight2_Title')}</PrincipleTitle>
              <PrincipleDescription>{t('Principal_Highlight2_Descr')}</PrincipleDescription>
            </PrincipleHighlight>
            <PrincipleHighlight>
              <PrincipleImage src={`${process.env.PUBLIC_URL}/img/pie.png`} alt="Principle 3" />
              <PrincipleTitle>{t('Principal_Highlight3_Title')}</PrincipleTitle>
              <PrincipleDescription>{t('Principal_Highlight3_Descr')}</PrincipleDescription>
            </PrincipleHighlight>
            <PrincipleHighlight>
              <PrincipleImage src={`${process.env.PUBLIC_URL}/img/shield.png`} alt="Principle 4" />
              <PrincipleTitle>{t('Principal_Highlight4_Title')}</PrincipleTitle>
              <PrincipleDescription>{t('Principal_Highlight4_Descr')}</PrincipleDescription>
            </PrincipleHighlight>
            <PrincipleHighlight>
              <PrincipleImage src={`${process.env.PUBLIC_URL}/img/principle5.png`} alt="Principle 5" />
              <PrincipleTitle>{t('Principal_Highlight5_Title')}</PrincipleTitle>
              <PrincipleDescription>{t('Principal_Highlight5_Descr')}</PrincipleDescription>
            </PrincipleHighlight>
          </PrincipleContainer>
        );
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <Container>
        <ButtonRow>
          <ButtonComponent
            text={t('AboutUs_Background')}
            transparent
            active={activePage === 'Background'}
            onClick={() => setActivePage('Background')}
          />
          <ButtonComponent
            text={t('AboutUs_Pnl')}
            transparent
            active={activePage === 'PNL'}
            onClick={() => setActivePage('PNL')}
          />
          <ButtonComponent
            text={t('AboutUs_Principle')}
            transparent
            active={activePage === 'Principle'}
            onClick={() => setActivePage('Principle')}
          />
        </ButtonRow>
        <Content>
          {renderContent()}
        </Content>
      </Container>
      <ButtonComponent text={t('CTA_ContactUs')} large transparent icon="/arrow-right.svg" onClick={() => window.open(telegramLink, '_blank')} />
    </Wrapper>
  );
};

export default AboutUsSection;