import i18n from 'i18next';

const getImageForResourceType = (type) => {
  switch (type) {
    case 'pdf':
      return `${process.env.PUBLIC_URL}/img/pdf-icon.svg`;
    case 'app':
      return `${process.env.PUBLIC_URL}/img/exe-icon.svg`;
    case 'excel':
      return `${process.env.PUBLIC_URL}/img/xsl-icon.svg`;
    default:
      return `${process.env.PUBLIC_URL}/img/default-icon.svg`;
  }
};

const getResources = () => {
  const resources = [
    {
      category: i18n.t('Resources_Intraday'),
      title: i18n.t('Resources_Intraday_exe'),
      typeDescr: i18n.t('Resources_Types_App'),
      type: 'app',
      color: '#81A2D5',
      downloadLink: i18n.t('Resources_Intraday_exe_Url')
    },
    {
      category: i18n.t('Resources_Intraday'),
      title: i18n.t('Resources_Manual'),
      typeDescr: i18n.t('Resources_Types_PDF'),
      type: 'pdf',
      color: '#81A2D5',
      urlLink: i18n.t('Resources_Intraday_Manual_Url')
    },
    {
      category: i18n.t('Resources_Intraday'),
      title: i18n.t('Resources_Intraday_Calculator'),
      typeDescr: i18n.t('Resources_Types_Excel'),
      type: 'excel',
      color: '#81A2D5',
      urlLink: i18n.t('Resources_Intraday_Calculator_Url')
    },
    {
      category: i18n.t('Resources_Leaderboard'),
      title: i18n.t('Resources_Leaderboard_exe'),
      typeDescr: i18n.t('Resources_Types_App'),
      type: 'app',
      color: '#429A6D',
      downloadLink: i18n.t('Resources_Leaderboard_exe_Url')
    },
    {
      category: i18n.t('Resources_Leaderboard'),
      title: i18n.t('Resources_Manual'),
      typeDescr: i18n.t('Resources_Types_PDF'),
      type: 'pdf',
      color: '#429A6D',
      urlLink: i18n.t('Resources_Leaderboard_Manual_Url')
    },
    {
      category: i18n.t('Resources_Lending'),
      title: i18n.t('Resources_Lending_exe'),
      typeDescr: i18n.t('Resources_Types_Webapp'),
      type: 'app',
      color: '#FFD300',
      downloadLink: i18n.t('Resources_Lending_exe_Url')
    },
    {
      category: i18n.t('Resources_Lending'),
      title: i18n.t('Resources_Manual'),
      typeDescr: i18n.t('Resources_Types_PDF'),
      type: 'pdf',
      color: '#FFD300',
      urlLink: i18n.t('Resources_Lending_Manual_Url')
    },
    // {
    //   category: i18n.t('Resources_New'),
    //   title: i18n.t('Resources_New_title'),
    //   typeDescr: i18n.t('Resources_Types_App'),
    //   type: 'app',
    //   color: '#BDD636',
    // },
    // {
    //   category: i18n.t('Resources_New'),
    //   title: i18n.t('Resources_New_title'),
    //   typeDescr: i18n.t('Resources_Types_PDF'),
    //   type: 'pdf',
    //   color: '#BDD636',
    // },
  ];

  return resources.map(resource => ({
    ...resource,
    image: getImageForResourceType(resource.type),
  }));
};

export default getResources;