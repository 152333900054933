import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.background};
  margin-top: 12rem; /* Add more gap with the contents above */
  margin-bottom: 2rem; /* Add more gap with the contents above */

  @media (max-width: 48rem) {
    margin-top: 4rem; /* Adjust margin for mobile devices */
  }
`;

const SocialMediaRow = styled.div`
  display: flex;
  gap: 2rem; /* Increase the spacing between icons */
  margin-bottom: 1rem; /* Add more gap with the contents above */
`;

const SocialMediaIcon = styled.a`
  display: inline-block;
  width: 20px; /* Make the icons smaller */
  height: 20px; /* Make the icons smaller */
  background-size: cover;
  background-position: center;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
    width: 22px; /* Make the icons smaller */
    height: 22px; /* Make the icons smaller */
  }
`;

const FooterText = styled.p`
  color: ${({ theme }) => theme.colors.main};
  font-size: 0.8rem;

  @media (max-width: 48rem) {
   font-size: 0.6rem; /* Adjust font size for mobile devices */
  }
`;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <SocialMediaRow>
        <SocialMediaIcon href="https://instagram.com/timerade_?igshid=dll4szxqg7zp" target="_blank" rel="noopener noreferrer" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/instagram.svg)` }} />
        <SocialMediaIcon href="https://wa.me/85264894038" target="_blank" rel="noopener noreferrer" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/whatsapp.svg)` }} />
        <SocialMediaIcon href="https://www.facebook.com/BitcoinalgotradersTimerade/" target="_blank" rel="noopener noreferrer" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/facebook.svg)` }} />
        <SocialMediaIcon href="https://t.me/joinchat/AAAAAFYOumrdyUM2-o0npg" target="_blank" rel="noopener noreferrer" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/telegram.svg)` }} />
      </SocialMediaRow>
      <FooterText>{t('Footer')}</FooterText>
    </FooterContainer>
  );
};

export default Footer;