import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../components/Button'; // Adjust the import path as needed

const NotFoundContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
`;

const NotFoundImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 0;
`;

const NotFoundTitle = styled.h1`
  font-size: 2.6rem;
  margin-bottom: 0;

  @media (max-width: 70rem) {
    font-size: 2rem;
  }
`;

const NotFoundMessage = styled.p`
  font-size: 1.2rem;
  max-width: 40rem;
  margin-bottom: 2rem;

  @media (max-width: 70rem) {
    font-size: 1rem;
  }
`;

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <NotFoundContainer>
      <NotFoundImage src={`${process.env.PUBLIC_URL}/img/404.svg`} alt="Not Found" />
      <NotFoundTitle>{t('404_Title')}</NotFoundTitle>
      <NotFoundMessage>{t('404_Descr')}</NotFoundMessage>
      <ButtonComponent
        text={t('404_CTA')}
        large
        transparent
        icon={`/arrow-right.svg`}
        onClick={() => window.open('/', '_blank')}
      />
    </NotFoundContainer>
  );
};

export default NotFound;