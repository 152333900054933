import React from 'react';
import { useTranslation } from 'react-i18next';
import GeneralSection from '../components/GeneralSection'; // Import the GeneralSection component
import ResourcesSection from '../components/ResourcesSection'; // Import the ResourcesSection component

const Download = () => {
  const { t } = useTranslation();
  return (
    <GeneralSection heading={t('Download_Heading')} align="center">
      <ResourcesSection />
    </GeneralSection>
  );
};

export default Download;