import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ButtonComponent from './Button';
import getResources from '../Data/Resources';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 3rem;
`;

const Container = styled.div`
  display: flex;
  width: 80%;
  max-width: 76rem;
  min-height: 50rem;
  height: auto;
  flex-shrink: 0;
  border-radius: 1.5rem;
  flex-direction: column;
  align-items: center;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-top: 0;
  flex-wrap: wrap; /* Added this line to allow wrapping */
`;

const Content = styled.div`
  padding-top: 4rem;
  color: ${({ theme }) => theme.colors.white};
`;

const ResourcesPoolContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
`;

const ResourceContainer = styled.a`
  padding: 4rem 2.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 14rem;
  height: 12rem;  
  gap: 3rem;
  border-radius: 1.5rem;
  background: rgba(244, 244, 244, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(1px);
  text-decoration: none;
  color: inherit;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(189, 214, 54, 0.1);
  }
`;

const ResourceDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start; /* Align to flex-start */
`;

const CategoryTitle = styled.div`
  font-size: 0.875rem;
  color: ${({ theme, color }) => color || theme.colors.intraday};
  text-align: left;
`;

const ResourceTitle = styled.div`
  font-size: 1.25rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.white};
`;

const ResourceType = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.white70};
`;

const ResourceImage = styled.img`
  width: 100%;
  max-width: 3rem;
  height: auto;
`;

const ResourcesSection = () => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState('All');
  const resources = getResources();

  const renderResources = () => {
    const filteredResources = activePage === 'All' ? resources : resources.filter(resource => resource.category === t(`Resources_${activePage}`));
  
    return filteredResources.map((resource, index) => (
      <ResourceContainer
        key={index}
        href={resource.urlLink || resource.downloadLink}
        download={!!resource.downloadLink}
        target={resource.urlLink ? "_blank" : "_self"}
        rel={resource.urlLink ? "noopener noreferrer" : ""}
      >
        <ResourceImage src={resource.image} alt={resource.type} />
        <ResourceDetails>
          <CategoryTitle color={resource.color}>{t(`${resource.category}`)}</CategoryTitle>
          <ResourceTitle>{resource.title}</ResourceTitle>
          <ResourceType>{resource.typeDescr}</ResourceType>
        </ResourceDetails>
      </ResourceContainer>
    ));
  };

  return (
    <Wrapper>
      <Container>
        <ButtonRow>
          <ButtonComponent
            text={t('Resources_All')}
            transparent
            active={activePage === 'All'}
            onClick={() => setActivePage('All')}
          />
          <ButtonComponent
            text={t('Resources_Intraday')}
            transparent
            active={activePage === 'Intraday'}
            onClick={() => setActivePage('Intraday')}
          />
          <ButtonComponent
            text={t('Resources_Leaderboard')}
            transparent
            active={activePage === 'Leaderboard'}
            onClick={() => setActivePage('Leaderboard')}
          />
          <ButtonComponent
            text={t('Resources_Lending')}
            transparent
            active={activePage === 'Lending'}
            onClick={() => setActivePage('Lending')}
          />
        </ButtonRow>
        <Content>
          <ResourcesPoolContainer>
            {renderResources()}
          </ResourcesPoolContainer>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default ResourcesSection;