import React, { useState } from 'react';
import styled from 'styled-components';

// Container for the dropdown, positioned relative to its parent
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

// Button to toggle the dropdown menu, changes background based on state and props
const DropdownButton = styled.button`
  padding: 8px 16px;
  background: ${({ $isOpen, $transparent }) => ($isOpen || $transparent ? 'transparent' : 'rgba(244, 244, 244, 0.40)')};
  backdrop-filter: blur(2px);
  display: inline-flex;
  gap: 8px;
  cursor: pointer;
  border: none;
  outline: none;

  &:focus {
    outline: none;
  }
`;

// Menu that appears when the dropdown is open, positioned absolutely below the button
const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  backdrop-filter: blur(3px);
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  z-index: 1;
  background: rgba(0, 0, 0, 0.35);
`;

// Individual item in the dropdown menu, changes background on hover
const DropdownItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  text-align: left;
  font-size: 0.9rem;
  &:hover {
    color: ${({ theme }) => theme.colors.main}; /* Change text color */
    background: ${({ theme }) => 'rgba(189, 214, 54, 0.1)'}; /* Change text color */
    border-radius: 8px;
  }
`;

// Dropdown component that manages its open/close state and renders the button and menu
const Dropdown = ({ label, options, transparent, icon }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the dropdown menu
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    if (option.link) {
      window.location.href = option.link;
    } else if (option.onClick) {
      option.onClick();
    }
    setIsOpen(false);
  };

  return (
    <DropdownContainer onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <DropdownButton onClick={toggleDropdown} $isOpen={isOpen} $transparent={transparent}>
        {label}
        {icon && <div>{icon}</div>}
      </DropdownButton>
      <DropdownMenu $isOpen={isOpen}>
        {options.map((option) => (
          <DropdownItem key={option.value || option.label} onClick={() => handleOptionClick(option)}>
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default Dropdown;