import React, { useEffect, useRef, useState } from 'react';
import { createChart, ColorType } from 'lightweight-charts';
import { useTheme } from 'styled-components';

const ChartComponent = ({ data }) => {
  const chartContainerRef = useRef();
  const theme = useTheme(); // Access the theme
  const [, setSeriesMap] = useState(new Map());

  useEffect(() => {
    if (!chartContainerRef.current) return;

    const resizeObserver = new ResizeObserver(entries => {
      const { width, height } = entries[0].contentRect;
      if (chart) {
        chart.resize(width, height);
      }
    });

    const textColor = theme.colors.white70; // Use the theme to get the text color

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: 'transparent' },
        textColor,
      },
      crosshair: {
        color: '#758696',
      },
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      timeScale: {
        rightOffset: 0.2,
        fixLeftEdge: true,
        fixRightEdge: true,
        timeVisible: true,
        borderVisible: false,
        borderColor: 'white',
      },
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
        },
      },
      rightPriceScale: {
        autoScale: true,
        mode: 0,
        scaleMargins: {
          top: 0.15, // margin from the top of the chart
          bottom: 0.15, // margin from the bottom of the chart
        },
        borderVisible: false, // show border
        visible: true, // make scale visible
        labelVisible: true, // make labels visible
      },
    });

    resizeObserver.observe(chartContainerRef.current);

    chart.timeScale().fitContent();

    // Add multiple line series for each strategy and store them in a map
    const newSeriesMap = new Map();
    data.forEach(strategyData => {
      const series = chart.addLineSeries({
        color: strategyData.color,
        lineType: 2,
        lineWidth: 1.8,
        priceLineVisible: false, // Disable the price line
      });
      series.setData(strategyData.data);
      newSeriesMap.set(strategyData.name, series);
    });
    setSeriesMap(newSeriesMap);

    // Create a legend container
    const legendContainer = document.createElement('div');
    legendContainer.style.display = 'flex';
    legendContainer.style.flexDirection = 'row';
    legendContainer.style.flexWrap = 'wrap'; // Allow wrapping of legend items
    legendContainer.style.backgroundColor = 'rgba(0, 0, 0, 0)';
    legendContainer.style.color = textColor;
    legendContainer.style.paddingLeft = '5px';
    legendContainer.style.marginTop = '10px';
    legendContainer.style.columnGap = '1.2vh'; // Add spacing between columns
    legendContainer.style.fontSize = '1.2vh';

    // Add legend items for each strategy
    data.forEach(strategyData => {
      const series = newSeriesMap.get(strategyData.name); // Move this line inside the loop
      const legendItem = document.createElement('div');
      legendItem.style.display = 'flex';
      legendItem.style.alignItems = 'center';
      legendItem.style.marginBottom = '5px';
      legendItem.style.cursor = 'pointer'; // Make legend items clickable

      const colorBox = document.createElement('div');
      colorBox.style.width = '12px';
      colorBox.style.height = '12px';
      colorBox.style.backgroundColor = strategyData.color;
      colorBox.style.marginRight = '5px';

      const labelText = document.createElement('span');
      labelText.textContent = strategyData.name;

      legendItem.appendChild(colorBox);
      legendItem.appendChild(labelText);
      legendContainer.appendChild(legendItem);

      // Add click event to toggle series visibility
      legendItem.addEventListener('click', () => {
        if (series) {
          const isVisible = series.options().visible !== false;
          series.applyOptions({ visible: !isVisible });

          // Update legend item style to indicate visibility
          if (isVisible) {
            labelText.style.textDecoration = 'line-through';
            labelText.style.opacity = '0.5';
          } else {
            labelText.style.textDecoration = 'none';
            labelText.style.opacity = '1';
          }
        }
      });

      // Initialize legend item style based on initial visibility
      if (series && series.options().visible === false) {
        labelText.style.textDecoration = 'line-through';
        labelText.style.opacity = '0.5';
      }
    });

    chartContainerRef.current.appendChild(legendContainer);

    // Create a y-axis title
    const yAxisTitle = document.createElement('div');
    yAxisTitle.textContent = 'Cumulative Return';
    yAxisTitle.style.position = 'absolute';
    yAxisTitle.style.top = '50%';
    yAxisTitle.style.right = '-5px';
    yAxisTitle.style.transform = 'translate(50%, -50%) rotate(90deg)';
    yAxisTitle.style.color = textColor;
    yAxisTitle.style.fontSize = '14px';
    yAxisTitle.style.fontWeight = 'normal';
    yAxisTitle.style.whiteSpace = 'nowrap';

    chartContainerRef.current.appendChild(yAxisTitle);

    // Add CSS to hide the TradingView logo
    const logoStyle = document.createElement('style');
    logoStyle.innerHTML = `
      #tv-attr-logo {
        display: none !important;
      }
    `;
    document.head.appendChild(logoStyle);

    return () => {
      chart.remove();
      resizeObserver.disconnect();
      legendContainer.remove();
      yAxisTitle.remove();
      logoStyle.remove();
    };
  }, [data, theme]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        chartContainerRef.current.style.height = '200px';
      } else if (window.innerWidth <= 768) {
        chartContainerRef.current.style.height = '300px';
      } else {
        chartContainerRef.current.style.height = '400px';
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial height

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      ref={chartContainerRef}
      style={{
        width: '100%',
        height: '400px', // Default height
        position: 'relative',
      }}
    />
  );
};

export default ChartComponent;