import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';


export const HeroSection = styled.section`
  display: flex;
  flex-direction: row; /* Change to row to place items side by side */
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Space between text and image */
  text-align: left; /* Align text to the left */
  padding: 0px 3rem;
  margin-top: 5em;
  max-width: 1000px; /* Set a maximum width */
  margin-left: auto; /* Center the section horizontally */
  margin-right: auto; /* Center the section horizontally */

  @media (max-width: 52rem) {
    flex-direction: column-reverse; /* Change the order for mobile */
  }

  @media (max-width: 48rem) {
    margin-top: 0rem;
  }
`;
// New styled components for the product pages
export const ProductHeroSection = styled(HeroSection)`
`;

export const HeroHeading = styled.h1`
  white-space: pre-line;
  font-size: 3.2rem;
  line-height: 1.2;
  margin-bottom: 0.2em;
  margin-top: 0em;
  min-width: 40rem;

  @media (max-width: 80rem) {
    line-height: 1;
    font-size: 3rem;
    min-width: 0rem;
  }
  @media (max-width: 48rem) {
    line-height: 1;
    text-align: start;
  }
  @media (max-width: 25rem) {
    line-height: 1;
    text-align: start;
    font-size: 10vw;
  }
`;

export const Subheading = styled.h3`
  max-width: 50rem;
  font-size: 1.1rem;
  line-height: 1.75rem;
  margin-top: 0em;
  margin-bottom: 0.5em;
  max-width: 32rem;

  @media (max-width: 48rem) {
    text-align: start;
    font-size: 0.9rem;
    line-height: 1.75rem;
  }
`;

export const StatisticsContainer = styled.div`
  display: flex;
  margin-top: 4rem;
  width: 100%;
  gap: 3rem;

  @media (max-width: 60rem) {
    gap: 2rem; /* Adjust gap for vertical layout */
  }

  @media (max-width: 48rem) {
    // flex-direction: column; /* Stack items vertically */
    gap: 2.5rem; /* Adjust gap for vertical layout */
    margin-top: 1rem; /* Add space between heading and statistics */
  }
`;

export const StatisticItem = styled.div`
  display: flex;
  align-items: center; /* Align items horizontally */
  text-align: left;
  gap: 1rem; /* Add gap between icon and text */

  @media (max-width: 48rem) {
    gap: 0.5rem; /* Adjust gap for small screens */
  }
`;

export const StatisticIcon = styled.img`
  width: 4.2rem; /* Set icon width */
  height: auto; /* Set icon height */

  @media (max-width: 48rem) {
    width: 3.2rem; /* Adjust icon width for small screens */
  }
`;

export const StatisticText = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 9rem; /* Set a maximum width */
`;

export const StatisticNumber = styled.h2`
  font-weight: 500;
  font-size: 2.4rem;
  margin: 0;
  
  @media (max-width: 56rem) {
    font-size: 1.8rem;
  }

  @media (max-width: 48rem) {
    font-size: 1.6rem;
  }
`;

export const StatisticDescription = styled.p`
  font-size: 0.9rem;
  margin: 0;

  @media (max-width: 56rem) {
    font-size: 0.8rem;
  }

  @media (max-width: 48rem) {
    font-size: 0.7rem;
  }
`;

// New styled component for the image
export const HeroImage = styled.img`
  max-width: 100%;
  height: 28rem;
  object-fit: cover;

  @media (max-width: 80rem) {
    height: 24rem; /* Adjust height for medium screens */
  }

  @media (max-width: 60rem) {
    height: 22rem; /* Adjust height for small screens */
  }

  @media (max-width: 48rem) {
    height: 20rem; /* Adjust height for small screens */
    margin-left: auto; /* Center the image horizontally */
    margin-right: auto; /* Center the image horizontally */
    margin-top: -3rem; /* Add space between text and image on extra small screens */
    margin-bottom: 2rem; /* Add space between text and image on extra small screens */
  }

  @media (max-width: 25rem) {
    height: 18rem; /* Adjust height for extra small screens */
  }
`;


export const HeroProductType = styled.div`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white70}; /* Adjust color as needed */
  margin-bottom: 2rem;

  @media (max-width: 48rem) {
    margin-bottom: 0.9rem; /* Adjust margin for small screens */
    font-size: 0.8rem; /* Adjust font size for small screens */
  }
`;

export const ProductHeroHeading = styled(HeroHeading)`
  /* Inherit styles from HeroHeading */
`;

export const ProductHeading = styled(HeroHeading)`
  /* Inherit styles from HeroHeading */
  color: ${({ theme, color }) => theme.colors[color] || theme.colors.main}; /* Accept color from theme or default to secondary */
`;

export const ProductHeroContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center; /* Center items vertically */
  gap: 1rem; /* Add gap between items */

  @media (max-width: 52rem) {
    // max-width: 1200px;
`;

export const ProductHeroSubheading = styled(Subheading)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white60}; /* Adjust color as needed */

  @media (max-width: 48rem) {
    font-size: 0.9rem; /* Adjust font size for small screens */
  }
`;

export const ProductStatisticsContainer = styled(StatisticsContainer)`
  gap: 3rem;
  max-width: 120rem;
  margin-top: 2rem;
  margin-bottom: 1rem;


  @media (max-width: 60rem) {
    gap: 2rem; /* Adjust gap for vertical layout */
  }

  @media (max-width: 48rem) {
    gap: 1rem; /* Adjust gap for small screens */
    margin-top: 1rem;
    margin-bottom: 0.4rem;
  }
`;

export const OtherProductStatisticsContainer = styled(StatisticsContainer)`
  gap: 4rem;
  margin-top: 2rem;
  max-width: 120rem;
  margin-bottom: 1rem;

  @media (max-width: 60rem) {
    gap: 2rem; /* Adjust gap for vertical layout */
  }

  @media (max-width: 48rem) {
    margin-top: 1rem;
    margin-bottom: 0rem;
    gap: 1.6rem; /* Adjust gap for small screens */
  }
`;

export const OtherStatisticText = styled(StatisticText)`
  max-width: 23rem; /* Set a maximum width */
`;

export const ProductStatisticNumber = styled(StatisticNumber)`
  font-size: 2rem;

  @media (max-width: 60rem) {
    font-size: 1.6rem; /* Adjust font size for small screens */
  }

  @media (max-width: 48rem) {
    font-size: 1.1rem; /* Adjust font size for small screens */
  }
`;

export const ProductStatisticDescription = styled(StatisticDescription)`
  font-size: 0.9rem;

  @media (max-width: 60rem) {
    font-size: 0.7rem; /* Adjust font size for small screens */
  }

  @media (max-width: 48rem) {
    font-size: 0.6rem; /* Adjust font size for small screens */
  }
`;

export const ProductDescrContainer = styled.div`
  max-width: 32rem;
  display: flex;
  align-items: flex-start; /* Align items to the top */
  justify-content: flex-end; /* Align items to the right */
  flex-direction: column; /* Stack items vertically */
  margin-top: 2.8rem;

  @media (max-width: 52rem) {
    max-width: 25rem;
  }
  
  @media (max-width: 48rem) {
    margin-top: 0rem;
  }
`;

export const DescrImage = styled.img`
  height: 4rem; /* Adjust the width as needed */
  width: auto; /* Maintain aspect ratio */
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;

  @media (max-width: 48rem) {
    height: 3rem; /* Adjust the width as needed */
  }
`;

export const ProductHeroImage = styled.img`
  height: 22rem; /* Adjust the width as needed */
  width: auto; /* Maintain aspect ratio */
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;

  @media (max-width: 48rem) {
    height: 16rem; /* Adjust the width as needed */
    margin-top: -4.2rem; /* Add space between text and image on extra small screens */
  }

  @media (max-width: 36rem) {
    height: 15rem; /* Adjust the width as needed */
  }
`;

export const DescrHeading = styled.h2`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white60}; /* Adjust color as needed */
`;

export const DescrContent = styled.p`
  max-width: 32rem;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.productDescr}; /* Adjust color as needed */

  @media (max-width: 48rem) {
    font-size: 0.7rem;
  }
`;

// const UnderlinedText = styled.span`
//   text-decoration: underline;
//   cursor: pointer;
//   color: ${({ theme }) => theme.colors.whiteS};
//   font-weight: 500;
// `;
const UnderlinedTextHeading = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
`;

const Tooltip = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  max-width: 13rem;
  font-size: 0.8rem;
`;

const StatisticItemWithTooltip = ({ number, description, tooltipText }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleTooltipToggle = () => {
    setTooltipVisible(!tooltipVisible);
  };

  return (
    <StatisticItem>
      <OtherStatisticText>
        <ProductStatisticNumber>
          <UnderlinedTextHeading onClick={handleTooltipToggle}>{number}</UnderlinedTextHeading>
          {tooltipVisible && (
            <Tooltip visible={tooltipVisible}>
              {tooltipText}
            </Tooltip>
          )}
        </ProductStatisticNumber>
        <ProductStatisticDescription>{description}</ProductStatisticDescription>
      </OtherStatisticText>
    </StatisticItem>
  );
};

export const LeaderboardStat2 = () => {
  const { t } = useTranslation();

  return (
    <StatisticItemWithTooltip
      number={t('Leaderboard_SP_Stat2')}
      description={t('Leaderboard_SP_Stat2_Descr')}
      tooltipText={t('Leaderboard_SP_Stat2_Suppl')}
    />
  );
};

export const LeaderboardStat3 = () => {
  const { t } = useTranslation();

  return (
    <StatisticItemWithTooltip
      number={t('Leaderboard_SP_Stat3')}
      description={t('Leaderboard_SP_Stat3_Descr')}
      tooltipText={t('Leaderboard_SP_Stat3_Suppl')}
    />
  );
};

export const LendingStat3 = () => {
  const { t } = useTranslation();

  return (
    <StatisticItemWithTooltip
      number={t('Lending_SP_Stat3')}
      description={t('Lending_SP_Stat3_Descr')}
      tooltipText={t('Lending_SP_Stat3_Suppl')}
    />
  );
};