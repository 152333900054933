import React from 'react';
import styled from 'styled-components';
import ButtonComponent from './Button';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 8rem;
  max-width: 84rem;

  @media (min-width: 80rem) {
    min-width: 80rem;
  }
`;

export const Heading = styled.h1`
  white-space: pre-line;
  font-size: 2rem;
  margin: 0;
  margin-bottom: 3.2rem;
  padding: 0px 3rem;
  text-align: ${({ $align }) => $align || 'center'}; /* Use the $align prop */

  @media (max-width: 80rem) {
    line-height: 1;
    font-size: 1.8rem;
    text-align: ${({ $align }) => $align || 'center'}; /* Ensure text is centered or left-aligned */
  }
  @media (max-width: 48rem) {
    line-height: 1;
    text-align: ${({ $align }) => $align || 'center'}; /* Ensure text is centered or left-aligned */
  }
  @media (max-width: 25rem) {
    font-size: 1.5rem;
    line-height: 1;
    text-align: ${({ $align }) => $align || 'center'}; /* Ensure text is centered or left-aligned */
  }
`;

export const Subheading = styled.h4`
  font-size: 1rem;
  margin: 0; /* Adjust margin to be closer to the heading */
  margin-bottom: 0.5rem;
  padding: 0px 3rem;
  text-align: ${({ $align }) => $align || 'center'}; /* Use the $align prop */
  color: ${({ theme }) => theme.colors.white40}; /* Use a lighter color from the theme */
`;

const CTAButton = styled(ButtonComponent)`
`;

const GeneralSection = ({ heading, subheading, ctaText, ctaLink, children, align }) => {
  return (
    <Section>
      {subheading && <Subheading $align={align}>{subheading}</Subheading>}
      <Heading $align={align}>{heading}</Heading>
      {ctaText && ctaLink && (
        <CTAButton text={ctaText} transparent hoverStyle large onClick={() => window.location.href = ctaLink} />
      )}
      {children}
    </Section>
  );
};

export default GeneralSection;