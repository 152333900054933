import React from 'react';
import { useTranslation } from 'react-i18next';
import GeneralSection from '../components/GeneralSection'; // Import the GeneralSection component
import ButtonComponent from '../components/Button';
import {LendingStat3, ProductHeroImage, ProductDescrContainer, DescrImage, DescrContent, HeroProductType, ProductHeroSubheading, ProductHeroSection, OtherProductStatisticsContainer, StatisticItem, OtherStatisticText, ProductStatisticNumber, ProductStatisticDescription } from '../components/HeroComponents'; // Import styled components
import { HighlightImage, DefaultHighlightItem, ProdHighlight, DiscountContainer, LDiscountCard, DiscountHeadingContainer, DiscountHeading, DiscountNotesContainer } from '../components/DiscountCard'; // Import the DiscountCard components
import CompareProductCard, { DefaultCompareHighlightItem, OtherCompareHighlightItem, CompareContainer } from '../components/CompareCard'; // Import the custom highlight item
import { ExchangeCard, ExchangeContainer } from '../components/ExchangeCard'; // Import the ExchangeCard and ExchangeContainer components
import FaqContainer, { FaqSection, FaqItem } from '../components/FaqSection';
import PricingSection from '../components/PricingCard';
import HighlightCard, { CardsContainer } from '../components/HighlightCard'; // Import the HighlightCard component and CardsContainer


const LendingPro = () => {
  const { t } = useTranslation();
  const message = encodeURIComponent(t('CTA_TG_Default'));
  const telegramLink = `https://t.me/BAT_CustomerService?text=${message}`;
  return (
    <div>
      <ProductHeroSection>
        <div>
          <HeroProductType>{t('Product_Type')}</HeroProductType>
          {/* <ProductHeroContainer>
            <ProductHeroHeading className='facultyglyphic'>{t('Product_Hero_Heading')}</ProductHeroHeading>
            <ProductHeading className='facultyglyphic' color="lending">{t('Lending_Hero_Heading')}</ProductHeading>
          </ProductHeroContainer> */}
          <DescrImage src={`${process.env.PUBLIC_URL}/img/lending/lending.png`} alt="Description Image" />
          <ProductHeroSubheading>{t('Lending_Hero_Subheading')}</ProductHeroSubheading>
          <DescrContent>{t('Lending_Desrc')}</DescrContent>
          <OtherProductStatisticsContainer>
            <StatisticItem>
              <OtherStatisticText>
                <ProductStatisticNumber>{t('Lending_SP_Stat1')}</ProductStatisticNumber>
                <ProductStatisticDescription>{t('Lending_SP_Stat1_Descr')}</ProductStatisticDescription>
              </OtherStatisticText>
            </StatisticItem>
            <StatisticItem>
              <OtherStatisticText>
                <ProductStatisticNumber>{t('Lending_SP_Stat2')}</ProductStatisticNumber>
                <ProductStatisticDescription>{t('Lending_SP_Stat2_Descr')}</ProductStatisticDescription>
              </OtherStatisticText>
            </StatisticItem>
            <LendingStat3 />
          </OtherProductStatisticsContainer>
          <ButtonComponent text={t('CTA_Enquiry')} large transparent icon={`/arrow-right.svg`} onClick={() => window.open(telegramLink, '_blank')} />
        </div>
        <ProductDescrContainer>
            <ProductHeroImage src={`${process.env.PUBLIC_URL}/img/lending/lending_hero.svg`} alt="Description Image" />
        </ProductDescrContainer>
      </ProductHeroSection>
      <GeneralSection heading={t('ProductInfo')} align="center">
        <DiscountContainer>
            <LDiscountCard $bgColor="rgba(255, 211, 0, 0.025)">
                <DiscountHeadingContainer>
                    <DiscountHeading dangerouslySetInnerHTML={{ __html: t('Lending_Discount1') }} />
                </DiscountHeadingContainer>
                <DiscountNotesContainer>
                    <ProdHighlight>
                      <DefaultHighlightItem text={t('Lending_White_Highlight1')} />
                      <HighlightImage src={`${process.env.PUBLIC_URL}/img/lending/lending_white_plan.png`} alt="Highlight Icon" />
                    </ProdHighlight>
                    {/* <ButtonComponent text={t('CTA_ContactUs')} transparent large icon={`/arrow-right.svg`} onClick={() => window.open(telegramLink, '_blank')} /> */}
                </DiscountNotesContainer>
            </LDiscountCard>
            <LDiscountCard $bgColor="rgba(255, 211, 0, 0.025)">
                <DiscountHeadingContainer>
                    <DiscountHeading dangerouslySetInnerHTML={{ __html: t('Lending_Discount2') }} />
                </DiscountHeadingContainer>
                <DiscountNotesContainer>
                <ProdHighlight>
                      <DefaultHighlightItem text={t('Lending_Pro_Highlight1')} />
                      <DefaultHighlightItem text={t('Lending_Pro_Highlight2')} />
                    </ProdHighlight>
                    {/* <ButtonComponent text={t('CTA_ContactUs')} transparent large icon={`/arrow-right.svg`} onClick={() => window.open(telegramLink, '_blank')} /> */}
                </DiscountNotesContainer>
            </LDiscountCard>
          </DiscountContainer>
      </GeneralSection>
      <GeneralSection subheading={t('Edge_Subheading')} heading={t('Edge_Heading')} align="center">
        <CompareContainer>
          <CompareProductCard title={t('Lending_BAT')} isMain={true} bgColor="rgba(255, 211, 0, 0.05)">
            <DefaultCompareHighlightItem heading={t('Lending_Edge_1_Title')} description={t('Lending_Edge_1_Descr')} />
            <DefaultCompareHighlightItem heading={t('Lending_Edge_2_Title')} description={t('Lending_Edge_2_Descr')} />
          </CompareProductCard>
          <CompareProductCard title={t('Lending_Other_Products')} opacity={0.8}>
            <OtherCompareHighlightItem heading={t('Lending_Others_1_Title')} description={t('Lending_Others_1_Descr')} />
            <OtherCompareHighlightItem heading={t('Lending_Others_2_Title')} description={t('Lending_Others_2_Descr')} />
          </CompareProductCard>
        </CompareContainer>
      </GeneralSection>
      <GeneralSection heading={t('Pricing')} align="center">
        <PricingSection productType="Lending" />
      </GeneralSection>
      {/* <GeneralSection heading={t('Welcome_Offer')} subheading={t('Offer')} align="center">
        <PricingOfferSection productType="Lending" />
        <VertContainer>
            <HelperContainer>
                <HelperText dangerouslySetInnerHTML={{ __html: t('Discount_Lending') }}></HelperText>
            </HelperContainer>
            <ButtonComponent text={t('CTA_Start')} large transparent icon={`/arrow-right.svg`} onClick={() => window.open(telegramLink, '_blank')} />
        </VertContainer>
      </GeneralSection> */}
      <GeneralSection subheading={t('Lending_Steps_Subheading')} heading={t('Lending_Steps_Heading')} align="left">
        <CardsContainer>
            <HighlightCard
                imageSrc={`${process.env.PUBLIC_URL}/img/lending/Lending_step1.svg`}
                number="01"
                color="#BDD636"
                title={t('Lending_Step1_Title')}
                description={t('Lending_Step1_Descr')}
                />
            <HighlightCard
                imageSrc={`${process.env.PUBLIC_URL}/img/lending/Lending_step2.svg`}
                number="02"
                color="#BDD636"
                title={t('Lending_Step2_Title')}
                description={t('Lending_Step2_Descr')}
                />
            <HighlightCard
                imageSrc={`${process.env.PUBLIC_URL}/img/lending/Lending_step3.svg`}
                number="03"
                color="#BDD636"
                title={t('Lending_Step3_Title')}
                description={t('Lending_Step3_Descr')}
                />
        </CardsContainer>
      </GeneralSection>
      <ButtonComponent text={t('CTA_Start')} large transparent icon={`/arrow-right.svg`} onClick={() => window.open(telegramLink, '_blank')} />
      <GeneralSection subheading={t('Intraday_Exchange_Subheading')} heading={t('Intraday_Exchange_Heading')} align="center">
        <ExchangeContainer>
            <ExchangeCard logoSrc={`${process.env.PUBLIC_URL}/img/bitfinex.svg`} altText="Bitfinex" />
        </ExchangeContainer>
      </GeneralSection>
      <GeneralSection heading={t('Qna_Heading')} align="center">
        <FaqSection>
          <FaqContainer title={t('Qna_Capital')}>
            <FaqItem question={t('Qna_Capital_Q1')} answer={t('Qna_Capital_A1')} />
            <FaqItem question={t('Qna_Capital_Q2')} answer={t('Qna_Capital_A2')} />
            <FaqItem question={t('Qna_Capital_Q3')} answer={t('Qna_Capital_A3_Lending')} />
          </FaqContainer>
          <FaqContainer title={t('Qna_Subscription_Lending')}>
            <FaqItem 
              question={t('Qna_Subscription_Q1')} 
              answer={[
                t('Qna_Subscription_A1_p1'),
                t('Qna_Subscription_A1_p2'),
              ]}
            />
            <FaqItem question={t('Qna_Subscription_Q6')} answer={t('Qna_Subscription_A6')} />
          </FaqContainer>
          <ButtonComponent text={t('CTA_ContactUs')} large transparent icon={`/arrow-right.svg`} onClick={() => window.open(telegramLink, '_blank')} />
        </FaqSection>
      </GeneralSection>
    </div>
  );
};

export default LendingPro;