import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GlobalStyles from './GlobalStyles'; // Import the GlobalStyles component
import { useTranslation } from 'react-i18next';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Download from './pages/Download';
import Faq from './pages/Faq';
import Intraday from './pages/Intraday'; // Import the Intraday component
import IntradayPerformance from './pages/IntradayPerformance'; // Import the new performance page
import Leaderboard from './pages/Leaderboard';
import LendingPro from './pages/Lending';
import NotFound from './pages/NotFound'; // Import the NotFound component
import styled from 'styled-components';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel'; // Import Facebook Pixel

const MainContent = styled.div`
  margin-top: 160px; /* Adjust this value based on the height of your navbar */
`;

function App() {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    ReactGA.initialize('G-JXNZDV3YHH');
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };
    ReactPixel.init('1098511041958914', options); // Replace 'YOUR_PIXEL_ID' with your actual Facebook Pixel ID
    ReactPixel.pageView(); // For tracking page view
  }, []);

  return (
    // <Router basename="/testing">
    <Router>
      <GlobalStyles />
      <div>
        <Navbar currentLanguage={currentLanguage} changeLanguage={changeLanguage} />
        <MainContent>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/download" element={<Download />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/products/intraday" element={<Intraday />} /> 
            <Route path="/products/intraday/performance" element={<IntradayPerformance />} />
            <Route path="/products/leaderboard" element={<Leaderboard />} />
            <Route path="/products/lendingpro" element={<LendingPro />} />
            <Route path="*" element={<NotFound />} /> {/* Add this line */}
          </Routes>
        </MainContent>
        <Footer />
      </div>
    </Router>
  );
}

export default App;