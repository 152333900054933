import React from 'react';
import { useTranslation } from 'react-i18next';
import GeneralSection from '../components/GeneralSection'; // Import the GeneralSection component
import FaqContainer, { FaqSection, FaqItem } from '../components/FaqSection';

const Faq = () => {
  const { t } = useTranslation();
  return (
    <GeneralSection heading={t('Qna_Heading')} align="center">
      <FaqSection>
        <FaqContainer title={t('Qna_Capital')}>
          <FaqItem question={t('Qna_Capital_Q1')} answer={t('Qna_Capital_A1')} />
          <FaqItem question={t('Qna_Capital_Q2')} answer={t('Qna_Capital_A2')} />
          <FaqItem question={t('Qna_Capital_Q3')} answer={t('Qna_Capital_A3')} />
        </FaqContainer>
        <FaqContainer title={t('Qna_Subscription')}>
          <FaqItem 
            question={t('Qna_Subscription_Q1')} 
            answer={[
              t('Qna_Subscription_A1_p1'),
              t('Qna_Subscription_A1_p2'),
            ]}
          />
          <FaqItem 
            question={t('Qna_Subscription_Q2')} 
            answer={t('Qna_Subscription_A2')} 
            toggles={[
              { title: t('Qna_Subscription_A2_Toggle1'), description: t('Qna_Subscription_A2_Toggle1_Descr') },
              { title: t('Qna_Subscription_A2_Toggle2'), description: t('Qna_Subscription_A2_Toggle2_Descr') },
              { title: t('Qna_Subscription_A2_Toggle3'), description: t('Qna_Subscription_A2_Toggle3_Descr') }
            ]}
          />
          <FaqItem 
            question={t('Qna_Subscription_Q3')} 
            answer={[
              t('Qna_Subscription_A3_p1'),
              t('Qna_Subscription_A3_p2'),
            ]}
          />
          <FaqItem question={t('Qna_Subscription_Q4')} answer={t('Qna_Subscription_A4')} />
          <FaqItem 
            question={t('Qna_Subscription_Q5')} 
            answer={[
              t('Qna_Subscription_A5_p1'),
              t('Qna_Subscription_A5_p2'),
              t('Qna_Subscription_A5_p3'),
            ]}
          />
          <FaqItem question={t('Qna_Subscription_Q6')} answer={t('Qna_Subscription_A6')} />
          <FaqItem question={t('Qna_Subscription_Q7')} answer={t('Qna_Subscription_A7')} />
          <FaqItem question={t('Qna_Subscription_Q8')} answer={t('Qna_Subscription_A8')} />
        </FaqContainer>
      </FaqSection>
    </GeneralSection>
  );
};

export default Faq;