import React from 'react';
import { useTranslation } from 'react-i18next';
import GeneralSection from '../components/GeneralSection'; // Import the GeneralSection component
import ButtonComponent from '../components/Button';
import { LeaderboardStat3, LeaderboardStat2, ProductHeroImage, ProductDescrContainer, DescrImage, DescrContent, HeroProductType, ProductHeroSubheading, ProductHeroSection, OtherProductStatisticsContainer, StatisticItem, OtherStatisticText, ProductStatisticNumber, ProductStatisticDescription } from '../components/HeroComponents'; // Import styled components
import { DefaultHighlightItem, ProdHighlight, ProductContainer, VideoContainer, Video, VideoCaption, DiscountContainer, DiscountCard, DiscountHeadingContainer, DiscountHeading, DiscountNotesContainer } from '../components/DiscountCard'; // Import the DiscountCard components
import CompareProductCard, { LeaderboardCompareOther4, LeaderboardCompareOther3, DefaultCompareHighlightItem, OtherCompareHighlightItem, CompareContainer } from '../components/CompareCard'; // Import the custom highlight item
import { ExchangeCard, ExchangeContainer } from '../components/ExchangeCard'; // Import the ExchangeCard and ExchangeContainer components
import FaqContainer, { FaqSection, FaqItem } from '../components/FaqSection';
import PricingSection from '../components/PricingCard';
import HighlightCard, { CardsContainer } from '../components/HighlightCard'; // Import the HighlightCard component and CardsContainer


const Leaderboard = () => {
  const { t } = useTranslation();
  const message = encodeURIComponent(t('CTA_TG_Default'));
  const telegramLink = `https://t.me/BAT_CustomerService?text=${message}`;
  return (
    <div>
      <ProductHeroSection>
        <div>
          <HeroProductType>{t('Product_Type')}</HeroProductType>
          {/* <ProductHeroContainer>
            <ProductHeroHeading className='facultyglyphic'>{t('Product_Hero_Heading')}</ProductHeroHeading>
            <ProductHeading className='facultyglyphic' color="leaderboard">{t('Leaderboard_Hero_Heading')}</ProductHeading>
          </ProductHeroContainer> */}
          <DescrImage src={`${process.env.PUBLIC_URL}/img/leaderboard/leaderboard.png`} alt="Description Image" />
          <ProductHeroSubheading>{t('Leaderboard_Hero_Subheading')}</ProductHeroSubheading>
          <DescrContent>{t('Leaderboard_Desrc')}</DescrContent>
          <OtherProductStatisticsContainer>
            <StatisticItem>
              <OtherStatisticText>
                <ProductStatisticNumber>{t('Leaderboard_SP_Stat1')}</ProductStatisticNumber>
                <ProductStatisticDescription>{t('Leaderboard_SP_Stat1_Descr')}</ProductStatisticDescription>
              </OtherStatisticText>
            </StatisticItem>
            <LeaderboardStat2 />
            <LeaderboardStat3 />
          </OtherProductStatisticsContainer>
          <ButtonComponent text={t('CTA_Enquiry')} large transparent icon={`/arrow-right.svg`} onClick={() => window.open(telegramLink, '_blank')} />
        </div>
        <ProductDescrContainer>
            <ProductHeroImage src={`${process.env.PUBLIC_URL}/img/leaderboard/leaderboard_hero.svg`} alt="Description Image" />
        </ProductDescrContainer>
      </ProductHeroSection>
      <GeneralSection heading={t('ProductInfo')} align="center">
        <ProductContainer>
          <DiscountContainer>
              <DiscountCard $bgColor="rgba(66, 154, 109, 0.05)">
                  <DiscountHeadingContainer>
                      <DiscountHeading dangerouslySetInnerHTML={{ __html: t('Leaderboard_Discount1') }} />
                  </DiscountHeadingContainer>
                  <DiscountNotesContainer>
                      <ProdHighlight>
                          <DefaultHighlightItem text={t('Leaderboard_White_Highlight1')} />
                          <DefaultHighlightItem text={t('Leaderboard_White_Highlight2')} />
                          <DefaultHighlightItem text={t('Leaderboard_White_Highlight3')} />
                          <DefaultHighlightItem text={t('Leaderboard_White_Highlight4')} />
                      </ProdHighlight>
                      {/* <ButtonComponent text={t('CTA_ContactUs')} transparent large icon={`/arrow-right.svg`} onClick={() => window.open(telegramLink, '_blank')} /> */}
                  </DiscountNotesContainer>
              </DiscountCard>
              <DiscountCard $bgColor="rgba(66, 154, 109, 0.05)">
                  <DiscountHeadingContainer>
                      <DiscountHeading dangerouslySetInnerHTML={{ __html: t('Leaderboard_Discount2') }} />
                  </DiscountHeadingContainer>
                  <DiscountNotesContainer>
                      <ProdHighlight>
                          <DefaultHighlightItem text={t('Release')} />
                      </ProdHighlight>
                      {/* <ButtonComponent text={t('CTA_ContactUs')} transparent large icon={`/arrow-right.svg`} onClick={() => window.open(telegramLink, '_blank')} /> */}
                  </DiscountNotesContainer>
              </DiscountCard>
            </DiscountContainer>
            <VideoContainer>
              <Video controls>
                <source src={`${process.env.PUBLIC_URL}/img/leaderboard/Leaderboard.mov`} type="video/mp4" />
              </Video>
              <VideoCaption>{t('Leaderboard_Video_Cap')}</VideoCaption>
            </VideoContainer>
        </ProductContainer>
      </GeneralSection>
      <GeneralSection heading={t('Pricing')} align="center">
        <PricingSection productType="Leaderboard" />
      </GeneralSection>
      <GeneralSection subheading={t('Leaderboard_Steps_Subheading')} heading={t('Leaderboard_Steps_Heading')} align="left">
        <CardsContainer>
            <HighlightCard
                imageSrc={`${process.env.PUBLIC_URL}/img/leaderboard/Leaderboard_step1.svg`}
                number="01"
                color="#BDD636"
                title={t('Leaderboard_Step1_Title')}
                description={t('Leaderboard_Step1_Descr')}
                />
            <HighlightCard
                imageSrc={`${process.env.PUBLIC_URL}/img/leaderboard/Leaderboard_step2.svg`}
                number="02"
                color="#BDD636"
                title={t('Leaderboard_Step2_Title')}
                description={t('Leaderboard_Step2_Descr')}
                />
            <HighlightCard
                imageSrc={`${process.env.PUBLIC_URL}/img/leaderboard/Leaderboard_step3.svg`}
                number="03"
                color="#BDD636"
                title={t('Leaderboard_Step3_Title')}
                description={t('Leaderboard_Step3_Descr')}
                />
        </CardsContainer>
      </GeneralSection>
      <ButtonComponent text={t('CTA_Start')} large transparent icon={`/arrow-right.svg`} onClick={() => window.open(telegramLink, '_blank')} />
      <GeneralSection subheading={t('Edge_Subheading')} heading={t('Edge_Heading')} align="center">
        <CompareContainer>
          <CompareProductCard title={t('Leaderboard_BAT')} isMain={true} bgColor="rgba(66, 154, 109, 0.2)">
            <DefaultCompareHighlightItem heading={t('Leaderboard_Edge_1_Title')} description={t('Leaderboard_Edge_1_Descr')} />
            <DefaultCompareHighlightItem heading={t('Leaderboard_Edge_2_Title')} description={t('Leaderboard_Edge_2_Descr')} />
            <DefaultCompareHighlightItem heading={t('Leaderboard_Edge_3_Title')} description={t('Leaderboard_Edge_3_Descr')} />
            <DefaultCompareHighlightItem heading={t('Leaderboard_Edge_4_Title')} />
          </CompareProductCard>
          <CompareProductCard title={t('Leaderboard_Other_Products')} opacity={0.8}>
            <OtherCompareHighlightItem heading={t('Leaderboard_Others_1_Title')} description={t('Leaderboard_Others_1_Descr')} />
            <OtherCompareHighlightItem heading={t('Leaderboard_Others_2_Title')} description={t('Leaderboard_Others_2_Descr')} />
            <LeaderboardCompareOther3 />
            <LeaderboardCompareOther4 />
          </CompareProductCard>
        </CompareContainer>
      </GeneralSection>
      <GeneralSection subheading={t('Intraday_Exchange_Subheading')} heading={t('Intraday_Exchange_Heading')} align="center">
        <ExchangeContainer>
            <ExchangeCard logoSrc={`${process.env.PUBLIC_URL}/img/binance.svg`} altText="Binance" />
            <ExchangeCard logoSrc={`${process.env.PUBLIC_URL}/img/bybit.svg`} altText="Bybit" />
        </ExchangeContainer>
      </GeneralSection>
      <GeneralSection heading={t('Qna_Heading')} align="center">
        <FaqSection>
          <FaqContainer title={t('Qna_Capital')}>
            <FaqItem question={t('Qna_Capital_Q1')} answer={t('Qna_Capital_A1')} />
            <FaqItem question={t('Qna_Capital_Q2')} answer={t('Qna_Capital_A2')} />
            <FaqItem question={t('Qna_Capital_Q3')} answer={t('Qna_Capital_A3')} />
          </FaqContainer>
          <FaqContainer title={t('Qna_Subscription_Leaderboard')}>
            <FaqItem 
              question={t('Qna_Subscription_Q1')} 
              answer={[
                t('Qna_Subscription_A1_p1'),
                t('Qna_Subscription_A1_p2'),
              ]}
            />
            <FaqItem 
              question={t('Qna_Subscription_Q5')} 
              answer={[
                t('Qna_Subscription_A5_p1'),
                t('Qna_Subscription_A5_p2'),
                t('Qna_Subscription_A5_p3'),
              ]}
            />
          </FaqContainer>
          <ButtonComponent text={t('CTA_ContactUs')} large transparent icon={`/arrow-right.svg`} onClick={() => window.open(telegramLink, '_blank')} />
        </FaqSection>
      </GeneralSection>
    </div>
  );
};

export default Leaderboard;