import React from 'react';
import { useTranslation } from 'react-i18next';
import GeneralSection from '../components/GeneralSection'; // Import the GeneralSection component
import ButtonComponent from '../components/Button';
import { ProductHeroImage, ProductDescrContainer, DescrImage, DescrContent, HeroProductType, ProductHeroSubheading, ProductHeroSection, ProductStatisticsContainer, StatisticItem, StatisticText, ProductStatisticNumber, ProductStatisticDescription } from '../components/HeroComponents'; // Import styled components
import HighlightCard, { MHighlightCard, HighlightCardSteps1, HighlightCardSteps2, VersionCardsContainer, MinCardsContainer, LCardsContainer } from '../components/HighlightCard'; // Import the HighlightCard component and CardsContainer
import CompareProductCard, { DefaultCompareHighlightItem, OtherCompareHighlightItem, CompareContainer } from '../components/CompareCard'; // Import the custom highlight item
import { ExchangeCard, ExchangeContainer } from '../components/ExchangeCard'; // Import the ExchangeCard and ExchangeContainer components
import UserReviewSection from '../components/UserReview'; // Import the UserReviewSection component
import FaqContainer, { FaqSection, FaqItem } from '../components/FaqSection';

const Intraday = () => {
  const { t } = useTranslation();
  const message = encodeURIComponent(t('CTA_TG_Default'));
  const telegramLink = `https://t.me/BAT_CustomerService?text=${message}`;
  return (
    <div>
      <ProductHeroSection>
        <div>
          <HeroProductType>{t('Product_Type')}</HeroProductType>
          <DescrImage src={`${process.env.PUBLIC_URL}/img/intraday/intraday.png`} alt="Description Image" />
          <ProductHeroSubheading>{t('Intraday_Hero_Subheading')}</ProductHeroSubheading>
          <DescrContent>{t('Intraday_Desrc')}</DescrContent>
          <ProductStatisticsContainer>
            <StatisticItem>
              <StatisticText>
                <ProductStatisticNumber>{t('Intraday_SP_Stat1')}</ProductStatisticNumber>
                <ProductStatisticDescription>{t('Intraday_SP_Stat1_Descr')}</ProductStatisticDescription>
              </StatisticText>
            </StatisticItem>
            <StatisticItem>
              <StatisticText>
                <ProductStatisticNumber>{t('Intraday_SP_Stat2')}</ProductStatisticNumber>
                <ProductStatisticDescription>{t('Intraday_SP_Stat2_Descr')}</ProductStatisticDescription>
              </StatisticText>
            </StatisticItem>
            <StatisticItem>
              <StatisticText>
                <ProductStatisticNumber>{t('Intraday_SP_Stat3')}</ProductStatisticNumber>
                <ProductStatisticDescription>{t('Intraday_SP_Stat3_Descr')}</ProductStatisticDescription>
              </StatisticText>
            </StatisticItem>
            <StatisticItem>
              <StatisticText>
                <ProductStatisticNumber>{t('Intraday_SP_Stat4')}</ProductStatisticNumber>
                <ProductStatisticDescription>{t('Intraday_SP_Stat4_Descr')}</ProductStatisticDescription>
              </StatisticText>
            </StatisticItem>
          </ProductStatisticsContainer>
          <ButtonComponent text={t('CTA_Enquiry')} large transparent icon={`/arrow-right.svg`} onClick={() => window.open(telegramLink, '_blank')} />
        </div>
        <ProductDescrContainer>
            <ProductHeroImage src={`${process.env.PUBLIC_URL}/img/intraday/intraday_hero.svg`} alt="Description Image" />
        </ProductDescrContainer>
      </ProductHeroSection>
      <GeneralSection subheading={t('Edge_Subheading')} heading={t('Edge_Heading')} align="center">
        <CompareContainer>
          <CompareProductCard title={t('Intraday_BAT')} isMain={true} bgColor="rgba(50, 95, 163, 0.2)">
            <DefaultCompareHighlightItem heading={t('Intraday_Edge_1_Title')} description={t('Intraday_Edge_1_Descr')} />
            <DefaultCompareHighlightItem heading={t('Intraday_Edge_2_Title')} description={t('Intraday_Edge_2_Descr')} />
            <DefaultCompareHighlightItem heading={t('Intraday_Edge_3_Title')} description={t('Intraday_Edge_3_Descr')} />
          </CompareProductCard>
          <CompareProductCard title={t('Intraday_Other_Products')} opacity={0.8}>
            <OtherCompareHighlightItem heading={t('Intraday_Others_1_Title')} description={t('Intraday_Others_1_Descr')} />
            <OtherCompareHighlightItem heading={t('Intraday_Others_2_Title')} description={t('Intraday_Others_2_Descr')} />
            <OtherCompareHighlightItem heading={t('Intraday_Others_3_Title')} description={t('Intraday_Others_3_Descr')} />
          </CompareProductCard>
        </CompareContainer>
      </GeneralSection>
      <GeneralSection subheading={t('Intraday_Tips_Subheading')} heading={t('Intraday_Tips_Heading')} align="left">
        <LCardsContainer>
            <HighlightCard
                imageSrc={`${process.env.PUBLIC_URL}/img/intraday/tips4.svg`}
                number="01"
                title={t('Intraday_Tips_1_Title')}
                description={t('Intraday_Tips_1_Descr')}
                useDangerouslySetInnerHTML={true}
                />
            <HighlightCard
                imageSrc={`${process.env.PUBLIC_URL}/img/intraday/tips1.svg`}
                number="02"
                title={t('Intraday_Tips_2_Title')}
                description={t('Intraday_Tips_2_Descr')}
                />
            <HighlightCard
                imageSrc={`${process.env.PUBLIC_URL}/img/intraday/tips2.svg`}
                number="03"
                title={t('Intraday_Tips_3_Title')}
                description={t('Intraday_Tips_3_Descr')}
                />
            <HighlightCard
                imageSrc={`${process.env.PUBLIC_URL}/img/intraday/tips3.svg`}
                number="04"
                title={t('Intraday_Tips_4_Title')}
                description={t('Intraday_Tips_4_Descr')}
                />
        </LCardsContainer>
      </GeneralSection>
      <GeneralSection subheading={t('Intraday_Steps_Subheading')} heading={t('Intraday_Steps_Heading')} align="left">
        <VersionCardsContainer>
          <HighlightCardSteps1 />
        </VersionCardsContainer>
        <MinCardsContainer>
            <HighlightCardSteps2 />
            <MHighlightCard
                imageSrc={`${process.env.PUBLIC_URL}/img/step3.svg`}
                number="03"
                color="#BDD636"
                title={t('Intraday_Step3_Title')}
                description={t('Intraday_Step3_Descr')}
                />
        </MinCardsContainer>

      </GeneralSection>
      <GeneralSection subheading={t('Intraday_Exchange_Subheading')} heading={t('Intraday_Exchange_Heading')} align="center">
        <ExchangeContainer>
            <ExchangeCard logoSrc={`${process.env.PUBLIC_URL}/img/binance.svg`} altText="Binance" />
            <ExchangeCard logoSrc={`${process.env.PUBLIC_URL}/img/bybit.svg`} altText="Bybit" />
            <ExchangeCard logoSrc={`${process.env.PUBLIC_URL}/img/okx.svg`} altText="OKX" />
        </ExchangeContainer>
      </GeneralSection>
      <GeneralSection subheading={t('Intraday_Customer_Subheading')} heading={t('Intraday_Customer_Heading')} align="center">
        <UserReviewSection />
      </GeneralSection>
      <GeneralSection heading={t('Qna_Heading')} align="center">
        <FaqSection>
          <FaqContainer title={t('Qna_Capital')}>
            <FaqItem question={t('Qna_Capital_Q1')} answer={t('Qna_Capital_A1')} />
            <FaqItem question={t('Qna_Capital_Q2')} answer={t('Qna_Capital_A2')} />
            <FaqItem question={t('Qna_Capital_Q3')} answer={t('Qna_Capital_A3')} />
          </FaqContainer>
          <FaqContainer title={t('Qna_Subscription_Intraday')}>
            <FaqItem 
              question={t('Qna_Subscription_Q1')} 
              answer={[
                t('Qna_Subscription_A1_p1'),
                t('Qna_Subscription_A1_p2'),
              ]}
            />
            <FaqItem 
              question={t('Qna_Subscription_Q3')} 
              answer={[
                t('Qna_Subscription_A3_p1'),
                t('Qna_Subscription_A3_p2'),
              ]}
            />
            <FaqItem question={t('Qna_Subscription_Q4')} answer={t('Qna_Subscription_A4')} />
            <FaqItem question={t('Qna_Subscription_Q7')} answer={t('Qna_Subscription_A7')} />
          </FaqContainer>
          <ButtonComponent text={t('CTA_ContactUs')} large transparent icon={`/arrow-right.svg`} onClick={() => window.open(telegramLink, '_blank')} />
        </FaqSection>
      </GeneralSection>
    </div>
  );
};

export default Intraday;