import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ButtonComponent from './Button';

const Product = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 15rem;
  padding: 3rem 2.4rem;
  gap: 2rem; /* Gap between main areas */
  flex-shrink: 0;
  border-radius: 24px;
  background: rgba(244, 244, 244, 0.04);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(1px);
`;

const TitleDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0; /* No gap between title and description */
`;

const Title = styled.h3`
  font-size: 1.2rem;
  text-align: left;
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
`;

const Description = styled.p`
  font-size: 0.8rem;
  text-align: left;
  margin: 0;
  color: ${({ theme }) => theme.colors.white40};
`;

const HighlightsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 18px; /* Gap between each highlight item */
  flex-grow: 1; /* Allow the list to grow and take available space */
`;

const HighlightItem = styled.li`
  display: flex;
  align-items: center;
  gap: 0.6rem;
`;

const HighlightIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const HighlightText = styled.span`
  font-size: 0.9rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.white0};
`;

const UnderlinedText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white70};
`;

const Tooltip = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  max-width: 13rem;
  font-size: 0.8rem;
`;

const CTAButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const CTAButton = styled(ButtonComponent)`
  width: 100%; /* Make the button take the full width of the container */
`;

const ProductCard = ({ title, description, ctaText, ctaLink, children }) => {
  return (
    <Product>
      <TitleDescriptionContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TitleDescriptionContainer>
      <HighlightsList>
        {children}
      </HighlightsList>
      {ctaText && ctaLink && (
        <CTAButtonContainer>
          <CTAButton text={ctaText} transparent full onClick={() => window.location.href = ctaLink} />
        </CTAButtonContainer>
      )}
    </Product>
  );
};

// Default HighlightItem component with default icon
export const DefaultHighlightItem = ({ text }) => (
  <HighlightItem>
    <HighlightIcon src={`${process.env.PUBLIC_URL}/img/default-icon.svg`} alt="Highlight Icon" />
    <HighlightText>{text}</HighlightText>
  </HighlightItem>
);

// Custom HighlightItem component for ProductLendingHighlight3
export const ProductLendingHighlight3 = () => {
  const {t, i18n} = useTranslation();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleTooltipToggle = () => {
    setTooltipVisible(!tooltipVisible);
  };

  const isEnglish = i18n.language === 'en';

  return (
    <HighlightItem>
      <HighlightIcon src={`${process.env.PUBLIC_URL}/img/default-icon.svg`} alt="Highlight Icon" />
      <HighlightText>
        {isEnglish ? (
          <>
            <UnderlinedText onClick={handleTooltipToggle}>{t('ProductLendingHighlight3')}</UnderlinedText>
          </>
        ) : (
          <>
            <UnderlinedText onClick={handleTooltipToggle}>{t('ProductLendingHighlight3')}</UnderlinedText>
          </>
        )}
        {tooltipVisible && (
          <Tooltip visible={tooltipVisible}>
            {t('ProductLendingHighlight3_Suppl')}
          </Tooltip>
        )}
      </HighlightText>
    </HighlightItem>
  );
};

// Custom HighlightItem component for ProductLeaderboardHighlight3
export const ProductLeaderboardHighlight3 = () => {
  const {t, i18n} = useTranslation();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleTooltipToggle = () => {
    setTooltipVisible(!tooltipVisible);
  };

  const isEnglish = i18n.language === 'en';

  return (
    <HighlightItem>
      <HighlightIcon src={`${process.env.PUBLIC_URL}/img/default-icon.svg`} alt="Highlight Icon" />
      <HighlightText>
        {isEnglish ? (
          <>
            <UnderlinedText onClick={handleTooltipToggle}>{t('ProductLeaderboard_Highlight3')}</UnderlinedText>
          </>
        ) : (
          <>
            <UnderlinedText onClick={handleTooltipToggle}>{t('ProductLeaderboard_Highlight3')}</UnderlinedText>
          </>
        )}
        {tooltipVisible && (
          <Tooltip visible={tooltipVisible}>
            {t('ProductLeaderboard_Highlight3_Suppl')}
          </Tooltip>
        )}
      </HighlightText>
    </HighlightItem>
  );
};

// Define the ProductContainer styled component
export const ProductContainer = styled.div`
  display: flex;
  padding: 1.5rem;
  justify-content: center;
  align-items: flex-start;
  gap: 3.5rem;
  flex-wrap: wrap;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;

export default ProductCard;