import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  display: flex;
  width: 12rem;
  height: 5rem;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0.625rem;
  flex-wrap: wrap;
  background: rgba(244, 244, 244, 0.04);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  border-radius: 12px;
`;

const Logo = styled.img`
  max-width: 60%;
  max-height: auto;
`;

const ExchangeCard = ({ logoSrc, altText }) => {
  return (
    <Card>
      <Logo src={logoSrc} alt={altText} />
    </Card>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3.6rem;
  flex-wrap: wrap;

  @media (max-width: 86rem) {
    gap: 1.6rem;
  }

  @media (max-width: 40rem) {
    flex-direction: column;
    gap: 2rem;
  }
`;

const ExchangeContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

export { ExchangeCard, ExchangeContainer };