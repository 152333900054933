import styled from 'styled-components';

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: row; /* Change to row to place items side by side */
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Space between text and image */
  text-align: left; /* Align text to the left */
  margin-left: auto; /* Center the section horizontally */
  margin-right: auto; /* Center the section horizontally */
  gap: 3rem;
  flex-grow: 1; /* Ensure all cards have the same height */

  @media (max-width: 82rem) {
    flex-direction: column; /* Stack items vertically on small screens */
  }
`;

export const DiscountContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  // padding: 0px 3rem;

  @media (max-width: 48rem) {
      padding: 0px 0rem;
    }
`;

export const DiscountContainerTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  // padding: 0px 3rem;
  margin-top: 5rem;

  @media (max-width: 48rem) {
      padding: 0px 0rem;
    }
`;

export const DiscountCard = styled.div`
  width: 24rem;
  height: auto;
  border-radius: 1.5rem;
  background: ${({ $bgColor }) => $bgColor || 'rgba(50, 95, 163, 0.10)'};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(1px);
  padding: 2.4rem 3.2rem;

  @media (max-width: 80rem) {
    width: 80%; /* Adjust width for small screens */
    padding: 2.4rem 3rem; /* Adjust padding for small screens */
  }
  @media (max-width: 48rem) {
    width: 16rem; /* Adjust width for mobile devices */
    padding: 2rem 2.2rem; /* Adjust padding for mobile devices */
  }
`;

export const LDiscountCard = styled.div`
  width: 70%;
  height: auto;
  border-radius: 1.5rem;
  background: ${({ $bgColor }) => $bgColor || 'rgba(50, 95, 163, 0.10)'};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(1px);
  padding: 1.6rem 4rem;
  margin-left: 2rem;

    @media (max-width: 64rem) {
    // width: 80%; /* Adjust width for small screens */
    padding: 2rem 6rem; /* Adjust padding for small screens */
    }
    @media (max-width: 48rem) {
      padding: 1rem 2rem; /* Adjust padding for mobile devices */
      margin-left: 0;
    }
`;

export const DiscountCriteriaContainer = styled.div`
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
  width: fit-content;
  height: fit-content;
  background: rgba(129, 162, 213, 0.10);
  margin-bottom: 1.5rem; /* Add margin at the bottom */
`;

export const SDiscountCriteriaContainer = styled.div`
  padding: 0.4rem 1rem;
  border-radius: 0.5rem;
  width: fit-content;
  height: fit-content;
  background: rgba(129, 162, 213, 0.10);
  margin-bottom: 1rem; /* Add margin at the bottom */

  @media (max-width: 48rem) {
    padding: 0.3rem 0.5rem; /* Adjust padding for mobile devices */
  }
`;

export const Criteria = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.white70};

  @media (max-width: 80rem) {
    font-size: 0.7rem; /* Adjust font size for small screens */
  }

  @media (max-width: 48rem) {
    font-size: 0.6rem; /* Adjust font size for mobile devices */
  }
`;

export const DiscountHeadingContainer = styled.div`
  text-align: left;
  margin-bottom: 1.5rem; /* Add margin at the bottom */
`;

export const DiscountHeading = styled.h3`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 0; /* Remove top margin */
  margin-bottom: 0; /* Remove bottom margin */

  @media (max-width: 80rem) {
    font-size: 1.5rem; /* Adjust font size for small screens */
  }

  @media (max-width: 48rem) {
    font-size: 1.2rem; /* Adjust font size for mobile devices */
  }
`;

export const SDiscountHeading = styled.h3`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 0; /* Remove top margin */
  margin-bottom: 0; /* Remove bottom margin */

  @media (max-width: 72rem) {
    font-size: 1rem; /* Adjust font size for small screens */
  }

  @media (max-width: 48rem) {
    font-size: 0.9rem; /* Adjust font size for mobile devices */
  }
`;

export const DiscountSubHeading = styled.h3`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.white70};
  margin-top: 0; /* Remove top margin */
  margin-bottom: 1rem; /* Remove bottom margin */
  text-decoration: line-through; /* Add strikethrough */

  @media (max-width: 80rem) {
    font-size: 1rem; /* Adjust font size for small screens */
  }
`;

export const SDiscountSubHeading = styled.h3`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.white70};
  margin-top: 0; /* Remove top margin */
  margin-bottom: 1rem; /* Remove bottom margin */
  text-decoration: line-through; /* Add strikethrough */

  @media (max-width: 72rem) {
    font-size: 0.85rem; /* Adjust font size for small screens */
  }

  @media (max-width: 48rem) {
    font-size: 0.8rem; /* Adjust font size for mobile devices */
  }
`;

export const DiscountCTA = styled.h3`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white70};
  margin-bottom: 0.5rem; /* Remove bottom margin */
  text-align: left;

  @media (max-width: 72rem) {
    font-size: 0.9rem; /* Adjust font size for small screens */
  }

  @media (max-width: 48rem) {
    font-size: 0.7rem; /* Adjust font size for mobile devices */
  }
`;

export const ProductInfo = styled.h3`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.white};
  text-align: left;

  @media (max-width: 80rem) {
    font-size: 0.8rem; /* Adjust font size for small screens */
  }
`;

export const DiscountNotesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  @media (max-width: 80rem) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const NotesWrapper = styled.div`
  flex-direction: column;
  align-items: flex-start;
`;

export const SNotesWrapper = styled.div`
  flex-direction: column;
  align-items: flex-start;
  max-width: 16rem;
  margin-right: 1rem;

  @media (max-width: 80rem) {
    max-width: 24rem;
  }
`;

export const DiscountNotes = styled.p`
  font-size: 0.7rem;
  color: ${({ theme }) => theme.colors.white60};
  margin: 0;
  text-align: left;
  max-width: 30rem;

  @media (max-width: 72rem) {
    font-size: 0.6rem; /* Adjust font size for small screens */
  }

  @media (max-width: 48rem) {
    font-size: 0.55rem; /* Adjust font size for mobile devices */
  }
`;


export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 48rem;
  margin: 0; /* Add some margin for spacing */

  @media (max-width: 80rem) {
    margin: 1rem 0; /* Adjust margin for small screens */
    max-width: 42rem; /* Adjust width for small screens */
  }

  @media (max-width: 48rem) {
    max-width: 80%; /* Adjust width for mobile devices */
  }
`;

export const Video = styled.video`
  height: auto;
  width: 100%;
  border-radius: 8px; /* Optional: Add rounded corners */
`;

export const VideoCaption = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.white60};
  margin-top: 0.5rem;
  text-align: center;

  @media (max-width: 80rem) {
    font-size: 0.7rem; /* Adjust font size for small screens */
  }
`;

const HighlightsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 18px; /* Gap between each highlight item */
  flex-grow: 1; /* Allow the list to grow and take available space */
`;

const HighlightItem = styled.li`
  display: flex;
  align-items: center;
  gap: 0.6rem;
`;

const HighlightIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const HighlightText = styled.span`
  font-size: 0.8rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: 80rem) {
    font-size: 0.72rem; /* Adjust font size for small screens */
  }
`;

export const HighlightImage = styled.img`
  width: 100%;
  max-width: 36rem;
  height: auto;
`;

export const ProdHighlight = ({ children }) => {
  return (
      <HighlightsList>
        {children}
      </HighlightsList>
  );
};

export const DefaultHighlightItem = ({ text }) => (
  <HighlightItem>
    <HighlightIcon src={`${process.env.PUBLIC_URL}/img/default-icon.svg`} alt="Highlight Icon" />
    <HighlightText>{text}</HighlightText>
  </HighlightItem>
);
export default DiscountCard;