import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { LDiscountCard, DiscountContainer, SDiscountCriteriaContainer, Criteria, DiscountHeadingContainer, SDiscountHeading, SDiscountSubHeading, DiscountNotesContainer, SNotesWrapper, DiscountNotes, DiscountCTA } from '../components/DiscountCard'; // Import the DiscountCard components
import ButtonComponent from './Button';

export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Ensure all cards have the same height */
  gap: 1rem;
  padding: 0px 3rem;

  @media (max-width: 72rem) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Maximum of 2 cards per row */
    gap: 1rem;
  }

  @media (max-width: 56rem) { 
    flex-direction: column; /* Stack cards vertically on small screens */
  }
`;

export const MinCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Ensure all cards have the same height */
  gap: 1rem;
  padding: 0px 3rem;

  @media (max-width: 76rem) { 
    flex-direction: column; /* Stack cards vertically on small screens */
  }
`;

export const VersionCardsContainer = styled.div`
  justify-content: space-between;
  align-items: stretch; /* Ensure all cards have the same height */
  gap: 1rem;
  padding: 0px 3rem;

  @media (max-width: 76rem) { 
    flex-direction: column; /* Stack cards vertically on small screens */
  }
`;

export const LCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Ensure all cards have the same height */
  gap: 1rem;
  padding: 0px 3rem;

  @media (max-width: 80rem) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Maximum of 2 cards per row */
    gap: 1rem;
  }

  @media (max-width: 56rem) { 
    flex-direction: column; /* Stack cards vertically on small screens */
  }
`;

export const MCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensure all cards have the same height */
  flex-direction: row;
  gap: 1rem;
  background: rgba(244, 244, 244, 0.05);
  flex-grow: 1; /* Ensure all cards have the same height */
  flex: 1;
  padding: 1.5rem 2rem;
  border-radius: 1rem;
  min-width: 14rem;

  @media (max-width: 56rem) { 
    flex-direction: column; /* Stack cards vertically on small screens */
    mw-width: 100%;
  }
`;

const MCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 80rem) {
    max-width: 100%;
  }

  @media (max-width: 76rem) {
    max-width: 24rem;
    margin-right: 20%;
  }

  @media (max-width: 56rem) {
    flex-direction: column; /* Stack cards vertically on small screens */
    margin-right: 1rem;
    max-width: 20rem;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 2rem;
  border-radius: 1rem;
  background: rgba(244, 244, 244, 0.05);
  min-width: 14rem;
  max-width: 15rem;
  flex-grow: 1; /* Ensure all cards have the same height */
  flex: 1;

  @media (max-width: 80rem) {
    max-width: 100%;
  }
`;

const LCard = styled.div`
  display: flex;
  justify-content: center; /* Center contents horizontally */
  align-items: center; /* Center contents vertically */
  gap: 1rem;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  background: rgba(244, 244, 244, 0.05);

  @media (max-width: 64rem) {
    flex-direction: column; /* Stack cards vertically on small screens */
  }
`;

const LCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4rem;
  border-radius: 1rem;
  min-width: 20rem;
  max-width: 15rem;

  @media (max-width: 80rem) {
    padding: 2rem;
  }

  @media (max-width: 56rem) {
    padding: 1rem;
    min-width: 14rem;
    max-width: 15rem;
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 1rem;

  @media (max-width: 56rem) {
    width: 80%;
    margin: 0 auto; /* Center the image horizontally */
  }
`;

const MCardImage = styled.img`
  width: 100%;
  max-width: 20rem;
  height: auto;
  margin-bottom: 1rem;

  @media (max-width: 70rem) {
    width: 80%;
    margin: 0 auto; /* Center the image horizontally */
  }

  @media (max-width: 56rem) {
    width: 60%;
    margin: 0 auto; /* Center the image horizontally */
  }
`;

const Numbering = styled.h1`
  font-size: 3.6rem;
  font-weight: 500;
  color: ${({ color, theme }) => color || theme.colors.intraday}; /* Use color prop or default to theme color */
  margin: 0;

  @media (max-width: 80rem) {
    font-size: 3rem;
  }
`;

const Title = styled.h3`
  font-size: 1.2rem;
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  text-align: left;

  @media (max-width: 80rem) {
    font-size: 1rem;
  }
`;
  
const Description = styled.p`
  font-size: 0.8rem;
  text-align: left;
  margin: 0;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.white70};
  flex-grow: 1; /* Ensure the description takes up the remaining space */

  @media (max-width: 80rem) {
    font-size: 0.rem;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  max-width: 13rem;
  text-align: justify;
  font-size: 0.8rem;
`;

const UnderlinedText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white70};
`;

export const VertContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
`;

export const HelperContainer = styled.div`
  display: flex;
  padding: 1.2rem 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background: rgba(255, 211, 0, 0.05);
  width: fit-content;
  height: fit-content;

  @media (max-width: 56rem) {
    max-width: 70%;
  }
`;

export const HelperText = styled.p`
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.white70};
    margin: 0;
    text-align: center;
`;

const HighlightCard = ({ imageSrc, number, title, description, color, useDangerouslySetInnerHTML = true }) => {
  return (
    <Card>
      <CardImage src={imageSrc} alt={title} />
      <Numbering color={color}>{number}</Numbering>
      <Title>{title}</Title>
      {useDangerouslySetInnerHTML ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : (
        <Description>{description}</Description>
      )}
    </Card>
  );
};

export const MHighlightCard = ({ imageSrc, number, title, description, color, useDangerouslySetInnerHTML = true }) => {
  return (
    <MCardsContainer>
      <MCard>
        <Numbering color={color}>{number}</Numbering>
        <Title>{title}</Title>
        {useDangerouslySetInnerHTML ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : (
          <Description>{description}</Description>
        )}
      </MCard>
      <MCardImage src={imageSrc} alt={title} />
    </MCardsContainer>
  );
};

export const LHighlightCard = ({ imageSrc, number, title, description, color, useDangerouslySetInnerHTML = true }) => {
  return (
      <LCardContainer>
        <CardImage src={imageSrc} alt={title} />
        <Numbering color={color}>{number}</Numbering>
        <Title>{title}</Title>
        {useDangerouslySetInnerHTML ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : (
          <Description>{description}</Description>
        )}
      </LCardContainer>
  );
};

export const HighlightCardSteps1 = () => {
    const {t} = useTranslation();
    const message = encodeURIComponent(t('CTA_TG_Default'));
    const telegramLink = `https://t.me/BAT_CustomerService?text=${message}`;
    
    return (
      <LCard>
        <LHighlightCard
          imageSrc={`${process.env.PUBLIC_URL}/img/step1.svg`}
          number="01"
          color="#BDD636"
          title={t('Intraday_Step1_Title')}
          description={t('Intraday_Step1_Descr')}
        />
        <DiscountContainer>
          <LDiscountCard>
              <SDiscountCriteriaContainer>
                  <Criteria dangerouslySetInnerHTML={{ __html: t('Intraday_Discount_Criteria1') }} />
              </SDiscountCriteriaContainer>
              <DiscountHeadingContainer>
                  <SDiscountHeading dangerouslySetInnerHTML={{ __html: t('Intraday_Discount1') }} />
                  <SDiscountSubHeading dangerouslySetInnerHTML={{ __html: t('Intraday_Discount1_Org') }} />
              </DiscountHeadingContainer>
              <DiscountNotesContainer>
                  <SNotesWrapper>
                      <DiscountNotes>{t('Intraday_Discount_Notes1')}</DiscountNotes>
                      <DiscountNotes>{t('Intraday_Discount_Notes2')}</DiscountNotes>
                  </SNotesWrapper>
                  <ButtonComponent text={t('CTA_Enquiry')} transparent large icon={`/arrow-right.svg`} onClick={() => window.open(telegramLink, '_blank')} />
              </DiscountNotesContainer>
          </LDiscountCard>
          <LDiscountCard>
              <SDiscountCriteriaContainer>
                  <Criteria dangerouslySetInnerHTML={{ __html: t('Intraday_Discount_Criteria2') }} />
              </SDiscountCriteriaContainer>
              <DiscountHeadingContainer>
                  <SDiscountHeading dangerouslySetInnerHTML={{ __html: t('Intraday_Discount2') }} />
              </DiscountHeadingContainer>
              <DiscountNotesContainer>
                  <SNotesWrapper>
                      <DiscountCTA>{t('Intraday_Discount2_Org')}</DiscountCTA>
                  </SNotesWrapper>
                  <ButtonComponent text={t('CTA_ContactUs')} transparent large icon={`/arrow-right.svg`} onClick={() => window.open(telegramLink, '_blank')} />
              </DiscountNotesContainer>
          </LDiscountCard>
        </DiscountContainer>
      </LCard>
    );
}

export const HighlightCardSteps2 = () => {
    const {t} = useTranslation();
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const handleTooltipToggle = () => {
        setTooltipVisible(!tooltipVisible);
    };

    return (
        <MHighlightCard
          imageSrc={`${process.env.PUBLIC_URL}/img/step2.svg`}
          number="02"
          color="#BDD636"
          title={t('Intraday_Step2_Title')}
          description={
            <>
              {t('Intraday_Step2_Descr').split(t('VM')).map((part, index, array) => (
                  <React.Fragment key={index}>
                      {part}
                      {index < array.length - 1 && (
                          <UnderlinedText onClick={handleTooltipToggle}>{t('VM')}</UnderlinedText>
                      )}
                  </React.Fragment>
              ))}
              {tooltipVisible && (
                  <Tooltip visible={tooltipVisible}>
                      {t('Intraday_Step2_Suppl')}
                  </Tooltip>
              )}
            </>
          }
          useDangerouslySetInnerHTML={false}
      />
    );
}
export default HighlightCard;